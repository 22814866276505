import React from 'react'
import heroSrc from './hero.png'
import styled from 'styled-components'
import {device} from 'shared/layout'
import {font} from 'shared/theme'
import { usePage } from 'shared/hooks/usePage'
import { ModalType } from 'shared/constants/modal'

const RxPlHero = styled.div`
  width: 100%;
  min-height: 220px;
  padding: 40px 0;
  margin-top: 34px;

  background: url(${heroSrc}) no-repeat;
  background-size: cover;
  background-position: top center;
   display: flex;
   flex-direction: column;
   justify-content: center;
  h1 {
   font-size:  2.5rem !important;
   line-height: 3.438rem !important;
   color: white;
   margin-bottom: 0;
  }

  p {
   color: white;
   font-size: 1.75rem;
   line-height: 2.125rem;
   margin: 0.9rem 0 2.5rem;
   ${font.light}
  }

  button {
   align-self: start;  
   min-width: 210px;
   font-weight: 600;
   min-height: 4.5rem;
  }

  .hero-wrapper {
   max-width: 1440px;
   width: 100%;
   margin: 0 auto;
   padding: 0;
  }

  .hero-title {
   text-align: center;
   width: 100%;
   background: rgba(49, 126, 255, 0.8);
   padding: 1.5rem;
   max-width: 500px;
   border-radius: 0.313rem;
  }

  a {
   align-self: start;  
   color: #317EFF;
   min-width: 210px;
   font-weight: 600px;
   padding: 1.5678rem 2rem;
   background: white;
   border-radius: 6px;
   text-align: center;
   font-size: 1.188rem;
   
   text-decoration: none;
   ${font.bold}
   display: inline-block;
   min-width: 16.25rem;

  }
  
  @media only screen and ${device.sm} {
      height: 560px;
      background-size: cover;
      background-position: top center;

      h1 {
         font-size:  3.125rem !important;
         line-height: 5rem !important;
         color: white;
      
      }

      padding: 80px 40px;


      .hero-wrapper {
         padding: 0 48px;
      }

      .hero-title {
         width: fit-content;
         padding: 2.375rem 2.5rem;

      }
   }

   @media only screen and ${device.lg} {
      height: 600px;
   }
`
const Hero = () => {
   const {setModal} = usePage()
   const handleRequestAccess = () => {
      setModal({type: ModalType.REQUEST_ACCESS })

   }
   return (
      <RxPlHero>
         <div className='hero-wrapper'>
            <div className='hero-title'>
               <h1>Save time. <br/>
                  Save money. <br/>
                  Do less work.</h1>
               <p>Your Single Source for Secondary Procurement</p>
               <a href="#" onClick={handleRequestAccess}>FIND OUT HOW</a>
            </div>

         </div>

      </RxPlHero>
   )
}

export default Hero
