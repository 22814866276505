
import styled from 'styled-components'
import {color, font} from 'shared/theme'
import {RxPlLayout, device} from 'shared/layout'


export const RxPlHomeLayout = styled(RxPlLayout)`

   position: relative;
   color: #1F479E;
   overflow: initial;
   scroll-margin-top: 6.25rem;

   h2 {
      ${font.medium}
      font-size: 2.375rem;
      line-height: 2.875rem;
      margin-bottom: 2rem !important;
   }

   h2 + p {
      ${font.light}
      font-size: 1.5rem;
      line-height: 1.3;
   }

   ul {
      list-style: none;
   }

   > p {
         font-size: 1.5rem;
         line-height: 1.875rem;
         ${font.light}
      }


   .center-align {
      text-align: center;
      margin-bottom: 4.375rem;
   }

   p {
      &.large {
         font-size: 1.5rem;
         line-height: 1.875rem
      }

      &.medium {
         font-size: 1.25rem;
         line-height: 1.75rem
      }
   }

   button {
      display: block;
      margin: 5rem auto 5rem;
      font-size: 1.188rem;
      line-height: 1.438rem;
      min-height: 72px;
      min-width: unset;
      padding-left: 30px;
      padding-right: 30px;
      ${font.bold}

      @media only screen and ${device.sm} {
         min-width: 16.875rem;

      }
   }

   > div {
      padding: 2rem 1.5rem;

      @media only screen and ${device.sm} {
         padding: 3.75rem 3rem;
      }
   }

 
   &.rxplace-offerings {
      h3 {
         font-size: 2.5rem;
         line-height: 3.125rem;  
         ${font.regular};
         margin-bottom: 1.5rem;
         span {
            font-size: 2.5rem;
            line-height: 3rem;  
            display: block;
         }
      }

      button {
         margin-bottom: 0;
         margin-top: 3.75rem;
      }

      ul {
         list-style: none;
         display: block;
         margin-bottom: 4.375rem;
         li {
            padding: 4rem 1.5rem;
            border-radius: 0.313rem;
            border: 2px solid ${color.blue};
            text-align: center;
            flex: 1;
            margin-bottom : 1.5rem;
            p {
               font-size: 1.25rem;
               line-height: 1.5rem;

            }
         }
      }

      @media only screen and ${device.sm} {
         

            h3 {
               font-size: 3.75rem;
               line-height: 4.5rem;  
               span {
                  font-size: 2.5rem;
                  line-height: 3rem;  
               }
            }
            ul {
               display: flex;
               gap: 4.7%;
            li {
               padding: 6.25rem 2.375rem;

               margin-bottom : 0;

            }
         }
         
         
         
      }
   }

   &.discover-the-product {
      padding-top: 0 !important;

      > div {
         padding-top: 0;
         padding-bottom: 0;
      }


      &:before {
         content: "";
         display: block;
         width: 100%;
         position: absolute;
         top: 50%;
         left: 0;
         transform: translateY(-50%);
         height: 90%;
         background-color: ${color.sky};
      }

      .content-wrapper {
         max-width: 990px;
         margin: 0 auto;
         background: white;
         padding: 2rem 1.5rem;
         position: relative;
         z-index: 1;
         border: 1px solid ${color.sky};
         border-radius: 5px;
      }

      .center-align {
         max-width: unset;
         margin-left: unset;
         margin-right: unset;
      }

      ul {
         margin: 0 -48px;
         padding-right: 48px;
         padding-left: 48px;
         padding-top: 1.875rem;
         padding-bottom: 1.875rem;

         border-top: 1px solid ${color.sky};

         li {
            display: flex;
            align-items: center;
            margin: 0;
            flex-grow: 1;
            flex-basis: 0;

            p {
               font-size: 1.5rem; 
               line-height:1.875rem;
               padding: 0;
               ${font.light};
               margin-bottom: 0;
               text-align: center;
            }

            img {
               width: 100%;
               max-width: 368px;
               margin: 0 auto;
               margin-bottom: 1.5rem;
            }

            &.text {
            }


         }

         &.flex-reversed {
            flex-direction: column-reverse;
            display: flex;
            
            p,
            img {
               align-self: flex-start;
            }
         }

         &.border-bottom {
            border-bottom: 1px solid ${color.sky};

         }
      }

      button {
         margin: 0.75rem auto 0;
      }

      @media only screen and ${device.sm} {


         .content-wrapper {
            padding: 3.75rem 3rem;
         }

         .center-align {
            max-width: 540px;
            margin-left: auto;
            margin-right: auto;
         }

         ul {
            &.flex-reversed {
               flex-direction: unset;
               align-items: center;

               p {
                  padding: 0 10%;
               }
            }

            li {

               &.image {
                  min-width: 50%;
               }

               img {
                  margin-bottom: 0;
               }
               p {
                  padding: 0 10%;
               }


            }

         }

      }
   }

   &.simple-process {
      .content-wrapper {
         padding: 2rem 1.5rem;
         background: ${color.sky};
         color: white;
         border-radius: 5px;
      }

      button {
         margin-bottom: 0;
         margin-top: 3.75rem;
         min-width: unset;
      }

      h3 {
         font-size: 4.375rem;
         border-radius: 6px;
         border: 2px solid white;
         padding: 2rem 1.5rem;
         width: fit-content;
         display: block;
         margin: 0 auto 1.5rem;
         min-width: 8rem;
         min-height: 8.625rem;
         display: flex;
         align-items: center;
         justify-content: center;
      }

      h4 {
         font-size: 1.875rem;
         ${font.regular}

         span {
            display: block;
            font-size: 1.25rem;
            line-height: 1.5rem;
            margin-top: 0.8rem;
         }
      }

      h3, h4 {
         text-align: center;
      }

      ul {
         display: block;
         li {
            text-align: center;
            flex: 1;
            margin-bottom: 1.4rem;

            .counter-wrapper {
               position: relative;
               width: 100%;
            }

            &:nth-child(2) {

               .counter-wrapper {
                  &:after {
                     content: unset;

                  }

                  &:before {
                     content: unset;
                  }
               }
            }

            &:after {
               content: "";
               width: 2px;
               height: 6rem;
               display: block;
               background: white;
               margin: 0 auto;
            }

            &:last-child {

               &:after {
                  content: unset;
               }
            }
         }

      
         * {
            align-self: flex-start;
         }
      }

      @media only screen and ${device.sm} {
         .content-wrapper {
            padding: 3.75rem 7.5rem;
         }
      }


      @media only screen and ${device.lg} {
         ul {
            display: flex;
            li {
               margin-bottom: 0;

               &:after {
                   content: unset;
               }
               
               &:nth-child(2) {
                   flex: 3;

                   .counter-wrapper {
                        &:after {
                           content: "";
                           position: absolute;
                           top: 50%;
                           transform: translateY(-50%);
                           right: -5.6%;
                           display: block;
                           width: 45%;
                           height: 2px;
                           background: white;
                        }

                        &:before {
                           content: "";
                           position: absolute;
                           top: 50%;
                           transform: translateY(-50%);
                           right: auto;
                           left: -5.6%;
                           display: block;
                           width: 45%;
                           height: 2px;
                           background: white;
                        }
                     }


               }
            }
         }

      }
   }



   &.footer {
      background: ${color.indigo};
      color: ${color.white};
      text-align: center;
      button {
         padding: 0;
         margin: 0;
         color: white;
         font-size: inherit;
    
      }

      p {
         font-size: 16px;
         text-align: center;
      }
   }



   @media only screen and ${device.sm} {
      h2 {
         margin-bottom: 56px;
      }


      form {
         input[type="email"] {
            margin-left: 0;
            width: 100%;
            max-width: 530px;
            margin-right: 38px;
         }

         input[type='submit'] {
            margin-right: 0;

            min-width: 160px;
            color: ${color.white};
            background: ${color.sky};
            border: 0;
         }
      }

   }


`

export const RxPlBlueThemeLayout = styled(RxPlHomeLayout)`
   background: #2F80ED;
   color: ${color.white};
   min-height: unset;

   ul {
      gap: 12%;
      li {
         padding: 0;
         h5 {
            margin-bottom: 32px;
         }
         img {
            margin-bottom: 24px;
            width: 100%;
         }
      }
   }

   @media only screen and ${device.sm} {
      ul {
         li {
            flex-grow: 1;
            flex-basis: 0;
         }
      }
   }

`

export const RxPlHomeScreenLayout = styled(RxPlLayout)`
   background: ${color.backgroundDefault};

   h3 {
      color: ${color.sky};
      font-size:  40px;
      line-height: 48px;
      font-weight: 900px;
      text-align: center;
   }
   h4 {
      margin-bottom: 36px;
      font-weight: 500;
      text-align: center;

   }

   .search-input {
      margin: 0 auto;
   }

   .carousel-recently-search {
      margin-top: 90px;
   }
   .spinner {
      margin-top: 100px;
   }


   @media only screen and ${device.sm} {
      h3:first-of-type {
      font-size: 40px;
      line-height: 48px;
      font-weight: 500;
      color: ${color.sky};
   }
   }
`

RxPlHomeScreenLayout.Content = styled(RxPlHomeScreenLayout.Content)`
   min-height: calc(100vh - 210px);
   position: relative;


`


export const RxPlFormFieldWrapper = styled.div`
   display: block;
   margin: 0 -8px;
   > div {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0 8px;
   }

   @media only screen and ${device.sm} {
      display: flex;
   }
`
   