
import Information from './Information'
import Licenses from './Licenses'
import Order from './Order'
import {Icon24} from 'shared/constants/icons'

export const data = [
   {
      id: 0,
      title: 'Account Information',
      path: "information",
      icon: Icon24.USER,
      view: Information
   },

   {
      id: 1,
      title: 'Order Summary',
      path: "order",
      icon: Icon24.BOX,
      view: Order
   },
   {
      id: 2,
      title: 'Licenses',
      path: "licenses",
      icon: Icon24.DOC,
      view: Licenses
   },


   {
      id: 3,
      title: 'Logout',
      path: "",
      icon: Icon24.LOGOUT,
      view: null
   }

]


export const orderHistoryFilter = [
   {
      label: "Last 30 days",
      value: ""
   },
   {
      label: "Last 20 days",
      value: "option1"
   },
   {
      label: "Last 10 days",
      value: "option2"
   }
]

export const orderSummary = {
      statusCode: 0,
      messages: [],
      validations: {},
      data: {
          orderSummary: [
              {
                  orderDate: "2023-07-06T00:00:00",
                  orderDateStr: "07/06/2023",
                  generic: 602.0000,
                  genericStr: "602.00",
                  brand: 21.0000,
                  brandStr: "21.00",
                  total: 623.0000,
                  totalStr: "623.00"
              }
          ],
          orderSummaryTotal: {
              genericTotal: 602.0000,
              genericTotalStr: "602.00",
              brandTotal: 21.0000,
              brandTotalStr: "21.00",
              grossTotal: 623.0000,
              grossTotalStr: "623.00"
          }
      },
      "userId": null
  } 
