import React, {forwardRef, useState, useRef} from 'react'
import {RxPlSubscribe} from './styles'
import Spinner from '../Spinner'
import Form from '../Form'
import Button from '../Button'
import useService from 'shared/hooks/useService'

const Subscribe = forwardRef( (props, ref) => {

   const {api} = useService()
   const [loading, setLoading] = useState(false)
   const [subscribe, setSubscribe] = useState(false)
   const [error, setError] = useState(false)
   const form = useRef(null)
     
   const signupHandler = async(e) => {
  
      e.preventDefault()
      const formData = new FormData(form.current)
      if (!formData.get("EmailAddress") ) return

      if (loading) return

      setLoading(true)


      formData.delete("g-recaptcha-response")
      const serializeFormData = Object.fromEntries(formData)

      try {

         await api().post("/subscribers",serializeFormData)
         setLoading(false)
         setSubscribe(true)

      } catch (error) {
         const {response} = error

         if (response && response.data) {
            console.log("ERROR" ,(response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || error)
         }

         setError(true)
         setLoading(false)


      }
   }

   const onError = (e) => {
      e.preventDefault()
      setError(false)
   }
   
   return (
      <RxPlSubscribe {...props} ref={ref}>
         <div className='wrapper'>

         <>
            <p>Created <strong>specifically for pharmacists by pharmacists</strong>, RxPlace enables you to focus on what matters most: your patients, your community, and your practice.</p>

            {
               subscribe && !error && !loading  &&
               <div className='success'><h2>Thank you for subscribing.We will be in touch soon.</h2></div>
            }

            {
               !subscribe && !loading && !error && 
               <Form ref={form}>
               <Form.Field className="form-field" name="EmailAddress" placeholder="Email Address" label="Subscribe to be the first to receive updates on exciting improvements and developments within our platform"/>
               <Button type="tertiary" onClick={signupHandler}>SIGN UP</Button>
                 </Form>
            }

            
            {
               error && !loading && !subscribe &&
               <>
                  <p className='error'>Something went wrong. <a href="#" onClick={onError}>Try again?</a>.</p>
               </>   
            }

         </>


         </div>

      </RxPlSubscribe>

   )
   

})

export default Subscribe
