import styled, { css } from 'styled-components'
import {color } from 'shared/theme'
const size = {
   xs: '320px',
   sm: '960px',
   lg: '1200px',
}

const device = {
   xs: `(min-width: ${size.xs})`,
   sm: `(min-width: ${size.sm})`,
   lg: `(min-width: ${size.lg})`
}

const RxPlLayout = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   text-align: ${props => props.textAlign || 'left'};
   background: ${props => props.background || 'none'};
   overflow-x: unset;

   p {
      font-size: 16px;
      line-height: 22px;
   }

   ${
    props => props.fullBleed && 
    css`
      max-width: unset;
      margin: 0;
      padding: 0;
    ` 
  }
   @media only screen and ${device.sm} {
      overflow-x: hidden;
      p {
         font-size: 20px;
         line-height: 28px;
      }
      ${
      props => props.fullBleed && 
      css`
         max-width: unset;
         margin: 0;
         padding: 0;
      ` 
      }
   }
`

RxPlLayout.Content = styled.div`
   display: block;
   width: 100%;
   max-width: 1440px;
   margin: 0 auto;
   padding: 48px 24px;
   @media only screen and ${device.sm} {
      padding: 48px;
      display: ${props => props.display || 'block'};
      justify-content: ${props => props.verticalAlignment || 'flex-start'};
      align-items: ${props => props.horizontalAlignment || 'flex-start'};
      text-align: ${props => props.textAlign || 'left'};
   }
` 

RxPlLayout.List = styled.ul`
   display: block;

   li {
      &:last-child {
         margin-bottom: 0;
      }
   }
   @media only screen and ${device.sm} {
      display: ${props => props.display ? props.display : 'block'};
   }

`

RxPlLayout.List.Item = styled.li`
   display: ${props => props.display ? props.display : 'block'};

   margin-bottom: 60px;
   img {
      width: 100%;
   }
   @media only screen and ${device.sm} {
      img { width: 50%;}
      display: ${props => props.display === 'block' ? 'block' : 'flex'};
      flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};

      div {
         padding-left: ${props => props.reverse ? '0' : '48px'};
         padding-right: ${props => props.reverse ? '48px' : '0'};

      }
      * {
         flex-grow: 1;
         flex-basis: 0;
      }
   }

`
RxPlLayout.List.ItemWrapper = styled.div`

   @media only screen and ${device.sm} {
      display: flex;
      flex-direction: column;
      flex-basis: unset;
      
      justify-content: ${props => props.verticalAlignment || 'flex-start'};
      align-items: ${props => props.horizontalAlignment || 'flex-start'};
      text-align: ${props => props.textAlign || 'left'}; 
   }

   * {
      flex-grow: 0;
   }

`

const RxPlLinkGroup = styled.div`
    * {
      &:first-child {
         margin-left: 0;
      }

      &:last-child {
         margin-right: 0;
      }
    }
`

const RxPlModal = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: rgba(0,0,0,0.8);
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 2000;
`

const RxPlCloseButton = styled.button`
   appearance: none;
   background: none;
   border: none;
   position: absolute;
   top: 16px;
   right: 16px;
   cursor: pointer;
   &:focus {
      outline: 0;
   }
   @media only screen and ${device.sm} {
      top: -30px;
      right: -40px;
   }

`

const RxPlContent = styled.div`
   padding-top: 110px;
   min-height: 100%;
   background: ${color.backgroundDefault};


   @media only screen and ${device.sm} {
      padding-top: 88px;
      min-height: calc(100vh - 140px);

   }
`


export {size, device, RxPlLayout, RxPlLinkGroup, RxPlModal, RxPlCloseButton, RxPlContent}