import {OrderDetails} from './styles'
import {RxPlInformationCard} from '../styles'
import placeholder from 'assets/images/productsample.jpg'

const Detail = props => {

   return (
      <>
         <RxPlInformationCard>
            <OrderDetails>
               <OrderDetails.Header>
                  <OrderDetails.HeaderItem>
                     <h6>Your Order:</h6>
                     <p>Order Date:</p>
                     <p>Status:</p>
                     <p>Order Placed By:</p>
                  </OrderDetails.HeaderItem>
                  <OrderDetails.HeaderItem>
                     <h6>123456789</h6>
                     <p>01/18/2021</p>
                     <p>Received</p>
                     <p>Jenny Jenkins</p>
                  </OrderDetails.HeaderItem>
               </OrderDetails.Header>
         
            </OrderDetails>

            <OrderDetails.Item>
               <div>
                  <h6>Shipping Address</h6>
                  <p>Joseph’s Pharmacy <br/>
                  Jenny Jenkins <br/>
                  1234 Silver Springs Ave. <br/>
                  Los Angeles, CA 91800 <br/>
                  United States</p>               </div>
               <div>
                  <h6>Shipping Method</h6>
                  <p>Joseph’s Pharmacy <br/>
                  Jenny Jenkins <br/>
                  1234 Silver Springs Ave. <br/>
                  Los Angeles, CA 91800 <br/>
                  United States</p>
               </div>

               <div>
                  <h6>Payment Method</h6>
                  <p>Visa ...0001 Exp: 12 / 2025</p>
               </div>

            </OrderDetails.Item>

            <OrderDetails.Item>
               <div className="product-info">
                  <img src={placeholder} alt="product info" />
                  <div>
                     <h6>Penicillin 500 MG Tablet</h6>
                     <p>Sandoz 1000 Tablets NDC: 6007890123</p>
                  </div>
               </div>

               <div className="unset-flex-basis unset-flex-grow text-align-center">
                  <h6>QTY</h6>
                  <p>6</p>
               </div>
               <div className="unset-flex-basis unset-flex-grow text-align-left">
                  <h6>Total</h6>
                  <p>$180.46</p>
               </div>
            </OrderDetails.Item>

            <OrderDetails.Item align="right">

               <div>
                  <h6>Wholesaler Total:</h6>
                  <p>Second Day Shipping:</p>
                  <p>Total (1 item):</p>
               </div>
               <div>
                  <h6>$180.46</h6>
                  <p>$20.00</p>
                  <p>$200.46</p>
               </div>
            </OrderDetails.Item>

      </RxPlInformationCard>
      <h4>Order Notes</h4>
      <RxPlInformationCard>
         <p>Your note to Broadway Rx</p>
         <p>There is no note from Sandoz</p>
      </RxPlInformationCard>
      </>
   )
}


export default Detail