
import {useContext, useRef, useState} from 'react'
import {RxPlCloseButton} from 'shared/layout'
import Spinner from 'shared/components/Spinner'
import {PageContext} from 'shared/contexts/PageContext'
import closeIcon from 'assets/icons/sqaure-close-btn.svg'
import pharmacistImage from 'assets/images/women_pharmacist.jpg'
import { RxPlRequestAccess } from './style'
import { RxPlFormFieldWrapper } from 'pages/Home/style'
import Form from '../Form'
import ReCAPTCHA from 'react-google-recaptcha'
import Card from '../Card'
import useService from 'shared/hooks/useService'
import Button from '../Button'


const RequestAccessModal = props => {
    const {
        setModal,
     }  = useContext(PageContext)
     
     const {api} = useService()
     const [loading, setLoading] = useState(false)
     const [added, setAdded] = useState(false)
     const [verified, setVerified] = useState(null)
     const form = useRef(null)

     const onChange = (value) => {

        setVerified(value)
  
     }
  
     const joinWaitlist = async(e) => {
  
        e.preventDefault()
        if (loading) return
  
        if (verified == null) return
  
        setLoading(true)
  
  
        const formData = new FormData(form.current)
        formData.delete("g-recaptcha-response")
        const serializeFormData = Object.fromEntries(formData)
  
        try {
  
           await api().post("/waitlist",serializeFormData)
           setLoading(false)
           setAdded(true)
           setVerified(null)
  
  
        } catch (error) {
           const {response} = error
  
           if (response && response.data) {
              console.log("ERROR" ,(response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || error)
           }
  
           setLoading(false)
  
  
        }
     }

    return (
    <RxPlRequestAccess>
        <RxPlCloseButton className='close-btn' onClick={ () => setModal({type: ""})}>
           <img src={closeIcon} alt="close icon" />
        </RxPlCloseButton>


        <Card type="signup" className={`${added && 'vertical-center'}`}>

        {
        added ? 
        <>
            <h2>Thank you for subscribing. We will be in touch soon.</h2>

        </> 
        :
        <>
        <h3>Request Access Now</h3>
        <Form ref={form}>
            <RxPlFormFieldWrapper>
                <Form.Field name="FirstName" placeholder="First Name" />
                <Form.Field name="LastName" placeholder="Last Name" />
            </RxPlFormFieldWrapper>
            <RxPlFormFieldWrapper>
                <Form.Field name="EmailAddress" placeholder="Email Address" />
            </RxPlFormFieldWrapper>

            <RxPlFormFieldWrapper>
                <Form.Field name="PharmacyName" placeholder="Institution Name" />
            </RxPlFormFieldWrapper>


            <RxPlFormFieldWrapper>
                <Form.Field name="PharmacyAddress" placeholder="Institution Address" />
            </RxPlFormFieldWrapper>
            <RxPlFormFieldWrapper className='button-group'>
                <ReCAPTCHA
                        sitekey="6LcQP18jAAAAAJbxG2N2PahMBygcfXAji0AYYQq6"
                        onChange={onChange}
                    />
                <Button type="blue" onClick={joinWaitlist}>JOIN NOW</Button>
            </RxPlFormFieldWrapper>
        </Form>
        </>

        }

        </Card>

        <div className='image'>
            <img src={pharmacistImage} alt="pharmacist" />
        </div>
     </RxPlRequestAccess>
    )
}


export default RequestAccessModal