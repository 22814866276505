import styled, {css} from 'styled-components'

export const RxPlDaysFilter = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-end;
   margin-bottom: 16px;
   p { 
      margin-right: 16px;
      margin-bottom: 0;
   }

   select {
      border: 0;
      padding: 8px 48px 8px 16px;
   }

   .filter-item {
      margin: 0 8px;

      label {
         margin: 0 8px;
      }
   }

   input[type="date"] { 
      outline: none;
      padding: 8px 16px 8px 16px;

   }
`


export const OrderDetails = styled.div`

`

OrderDetails.Header = styled.div`
   display: flex;
   margin: 0 -16px;

`

OrderDetails.HeaderItem = styled.div`
   margin: 0 16px;
`
OrderDetails.Item = styled.div`
   display: flex;
   margin: 24px -16px 32px;
   > div {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0 16px;
   }

   .product-info {
      display: flex;
      flex-grow: 3;
      img {
         margin-right: 16px;
      }

      h6 {
         margin-bottom: 24px;
      }
   }

   ${
    props => props.align === 'right' && 
    css`
      justify-content: flex-end;

      > div {
         flex-basis: unset;
         flex-grow: unset;
         margin: 0 16px;
         text-align: right;
      }


    ` 
  }


`


export const RxPlOrderNotes = styled.div`

`
