import React, {useState} from 'react'
import Button from 'shared/components/Button'
import {CheckoutProgress} from 'shared/constants/cart'
import Select from 'shared/components/Select'
import {RxPlSummaryCard, RxPlSummaryItemRow} from './styles'
import PropTypes from 'prop-types';
import { ShippingOptions } from 'shared/components/Modal/mock'

const propTypes = {
   progress: PropTypes.oneOf([CheckoutProgress.CART, CheckoutProgress.REVIEW, CheckoutProgress.CONFIRMATION, CheckoutProgress.NO_SAVINGS]),
}

const defaultProps = {
   progress: CheckoutProgress.CONFIRMATION
}
// https://dev.app.rxplaceinternal.com/pharmacy/api/CustomerShipping/customer/2/list
const CartSummary = props => {

   const [address, setAddress] = useState(null)
   if (props.noSavings === false) {
      return (
         <RxPlSummaryCard style={{textAlign: 'center'}}>
         <h6 style={{color: "#3362AB"}}><strong>That's a good looking order</strong></h6>
         <p>If we find a better combination of products to help you <span style={{color: "#3362AB" }}><strong>save even more</strong></span> we will display it here. </p>
         <p>So go ahead and keep shopping for what you need. We've got your back.</p>
         </RxPlSummaryCard>
      )
   }

   const {totalGrossAmountStr, totalNetAmountStr, totalShippingCostStr, savingsStr, cartUID } = props.data

   const shipppingAddreses = props.address && props.address.list.map ( add => {
      return {
         label: add.shipName,
         value: add.customerShippingId
      }
   })

   const onSelectAddress = e => {
      const id = e.target.value
      if (id === '' || !id) {
         setAddress(null)
         return
      }

      const result =  props.address.list.filter (ad => String(ad.customerShippingId) === String(id))[0]
      const {address1, city, state, zipCode, shipName, customerShippingId}  = result
      setAddress(
         {
            shipName,
            address1,
            city,
            state,
            zipCode,
            customerShippingId
         }
      )
   }

   return (
      <>
          {
         props.progress === CheckoutProgress.CART &&
         <RxPlSummaryCard>
            {
               savingsStr && savingsStr !== "0.00" && 
               <p className="amount-save">save <strong>${savingsStr}</strong> on this order</p>
            }
            {/* <h6>{props.title || "YOUR SELECTED ITEMS"}</h6> */}
            <RxPlSummaryItemRow>
               <p>Total Product Costs</p>
               <p className="align-right"><strong>${totalGrossAmountStr || "0.00"}</strong></p>
            </RxPlSummaryItemRow>
            <RxPlSummaryItemRow>
               <p>Total Shipping Cost</p>
               <p className="align-right"><strong>{totalShippingCostStr}</strong></p>
            </RxPlSummaryItemRow>

            <hr/>

            <RxPlSummaryItemRow>
               <h6>Total Cost</h6>
               <p className="align-right"><strong>${totalNetAmountStr || "0.00"}</strong></p>
            </RxPlSummaryItemRow>
            <Button onClick={() => props.proceedCheckoutHandler(cartUID)} type="primary">{props.buttonLabel}</Button>
         </RxPlSummaryCard>
      }

      {
         props.progress === CheckoutProgress.REVIEW &&
         <RxPlSummaryCard>
            <h6>Subtotal ( {props.summary.totalQuantity} {props.summary.totalQuantity > 1 ? "items" : "item"} )</h6>
            <RxPlSummaryItemRow>
               <p>Item(s) total</p>
               <p className="align-right">${props.summary.totalGrossAmountStr}</p>
            </RxPlSummaryItemRow>
            <RxPlSummaryItemRow>
               <p>Shipping total</p>
               <p className="align-right">${Number(props.summary.totalShippingCost).toFixed(2)}</p>
            </RxPlSummaryItemRow>

            <hr/>

            <RxPlSummaryItemRow>
               <h6>Total ({props.summary.totalQuantity} {props.summary.totalQuantity > 1 ? "items" : "item"})</h6>
               <p className="align-right"><strong>${props.summary.totalNetAmount}</strong></p>
            </RxPlSummaryItemRow>
            <Button onClick={props.proceedCheckoutHandler} type="primary" disabled>{props.buttonLabel}</Button>
         </RxPlSummaryCard>
      }

   
      {
         props.progress === CheckoutProgress.CONFIRMATION &&

         <RxPlSummaryCard>
            <h6>Order summary</h6>
            <hr/>
            {/* <Select data={state.shipping.list} onChange={onShippingListChange} value={state.shipping.option}/> */}

            {
               shipppingAddreses && shipppingAddreses.length > 1 ? <Select data={[
                  ...[
                     {
                        label: "Select delivery address",
                        value: ''
                     }
                  ],
                  ...shipppingAddreses ]}
                  onChange={onSelectAddress}
                /> 
               :
               <p><strong>Delivery address</strong></p>

            }
            {
               shipppingAddreses.length > 1 && address  && 
               <p>
                  {address.shipName} <br/>
                  {address.address1} <br/>
                  {address.city} {address.state} {address.zipCode} <br/>

               </p>
            }

            {
               shipppingAddreses.length === 1 && address === null && props.address && props.address.entity && 
               <p>
                  {props.address.entity.fullName} <br/>
                  {props.address.entity.contactName} <br/>
                  {props.address.address1} <br/>
                  {props.address.city} {props.address.state} {props.address.zipCode} <br/>

               </p>
            }
            {/* <p>
            Joseph’s Pharmacy <br/>
            Jenny Jenkins <br/>
            1234 Silver Springs Ave. <br/>
            Los Angeles, CA 91800 <br/>
            United States

            </p> */}
            <p><strong>Order details</strong></p>
            {
               props.data.length > 0 && 
                  
                     props.data.map ( (item,index) => 
                        <div key={index}>
                           <p className="mb-0"><strong>{item.fullName}</strong></p>
                           <RxPlSummaryItemRow className="indent"> 
                              <p className="title regular">Item(s) total</p>
                              <p className="align-right">${item.providerTotalGross || "300.38"}</p>
                           </RxPlSummaryItemRow>
                           <RxPlSummaryItemRow className="indent">
                              <p className="title regular">Shipping</p>
                              <p className="align-right">{item.shippingCost || "FREE"}</p>
                           </RxPlSummaryItemRow>
                        </div>
                     )


            }

           <hr/>
           <RxPlSummaryItemRow>
               <p className="title">Total ({props.summary.totalItemsInCart} {props.summary.totalItemsInCart > 1 ? 'items' : 'item'})</p>
               <p className="align-right"><strong>${props.summary.totalNetAmountStr || "200.38"}</strong></p>
          </RxPlSummaryItemRow>
          <Button onClick={()=>{props.proceedCheckoutHandler(address.customerShippingId)}} type="primary">{props.buttonLabel}</Button>

         </RxPlSummaryCard>
      }
      </>
   )
}

CartSummary.propTypes = propTypes
CartSummary.defaultProps = defaultProps


export default React.memo(CartSummary)