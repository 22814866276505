import {useState, useEffect} from 'react'
import Sidebar from 'shared/components/Sidebar'
import Topbar from 'shared/components/Topbar'
import Footer from 'shared/components/Footer'
import {RxPlAccountLayout} from './styles'
import {RxPlContent} from 'shared/layout'
import useService from 'shared/hooks/useService'
import { useHistory} from 'react-router-dom'
import {data} from './mock'
import Modal from 'shared/components/Modal'
import { usePage } from 'shared/hooks/usePage'

const Account = ({match}) => {
   const {authenticated, modal} = usePage()

   const history = useHistory()
   const {sessionIsAvailable} = useService()
   const [current, setCurrent] = useState(data.filter( it => it.id === match.params.path)[0] || data[0])
   const CurrenView = current.view

   const onChangeView = index => {
      setCurrent(data[index])
   }

   useEffect(()=>{
      if (!sessionIsAvailable()) {
         history.push("")
         return
      }
   }, [])
   return (
      <>
         { modal.type !== "" && <Modal {...modal} /> }

         <Topbar type='checkout' searchPlaceholder="Search by NDC, Product Name or Manufacturer" />
         <RxPlContent>
         <Sidebar data={data} handleClick={onChangeView} active={current.id} />
         <RxPlAccountLayout>
            <RxPlAccountLayout.Content>
               <h2>{current.title}</h2>
               {
                  authenticated && <CurrenView />
               }
            </RxPlAccountLayout.Content>
         </RxPlAccountLayout>
       </RxPlContent>
       <Footer logIn={true} />

      </>
   )
}


export default Account