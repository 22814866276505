import {useState} from 'react'
import Detail from './Detail'
import History from './History'
import { orderHistoryFilter } from '../mock'

const Order = () => {

   const [detailMode, setDetailMode] = useState(false)

   const getDetailsHandler = () => {
      setDetailMode(true)
   }

   return (
      <>
         {
            detailMode ? <Detail /> : <History getDetailsHandler={getDetailsHandler} filters={orderHistoryFilter} />

         }
      </>
   )
}


export default Order