import {useState, useEffect, useCallback} from 'react'
import Topbar from 'shared/components/Topbar'
import Modal from 'shared/components/Modal'
import Button from 'shared/components/Button'
import CartSummary from 'shared/components/Cart/Summary'
import ThankYou from './ThankYou'
import {RxPlContent} from 'shared/layout'
import PropTypes from 'prop-types'
import {ModalType} from 'shared/constants/modal'
import {CheckoutProgress} from 'shared/constants/cart'
import { usePage } from 'shared/hooks/usePage'
import { useCart } from 'shared/hooks/useCart'
import { useHistory, useLocation} from 'react-router-dom'
import useService from 'shared/hooks/useService'
import {CartGroup} from 'shared/components/Cart'
import { setTotalCartItems } from 'shared/contexts/CartContext/reducer'
import { gaPageview, gaPlaceOrder } from 'shared/utils/ga'

// import {cartMockData} from './data/mock'

import Cookies from 'js-cookie'

import {RxPlCheckoutLayout, 
      RxPlCheckoutHeader, 
      RxPlCheckoutContainer, 
      RxPlCartList, 
      RxPlCheckoutSummary
   } from './styles'
import Footer from 'shared/components/Footer'

const propTypes = {
   progress: PropTypes.oneOf([CheckoutProgress.REVIEW, CheckoutProgress.CONFIRMATION, CheckoutProgress.COMPLETED]),
 }

 const defaultProps = {
   progress: CheckoutProgress.CONFIRMATION,
 }



const Checkout = props => {
   // const steps = [CheckoutProgress.REVIEW, CheckoutProgress.CONFIRMATION, CheckoutProgress.COMPLETED ]
   const search = useLocation().search
   const cart = new URLSearchParams(search).get('cart')

   const [progress, setProgress] = useState(props.progress)
   const [cartUID, setcartUID] = useState(cart)
   const [cartData, setCartData] = useState({})
   const {modal, setModal} = usePage()
   const {dispatch, state, cartSummaryMapper, cartItemsMapper} = useCart()
   const history = useHistory()
   const [loading, setLoading] = useState(false)
   const {sessionIsAvailable, api, logout} = useService()

   const getDeliveryAddress = (data, list) => {
      const {address1, city, state, zipCode} = data.deliveryAddress

      const customerProfile =  Cookies.get("profile") ? JSON.parse(Cookies.get("profile")) : null
      const {customerId} = customerProfile
      
      return {
         fullName: data.customerEntity.fullName || customerProfile.fullName,
         contactName:  data.customerEntity.contactName || customerProfile.contactName,
         email: customerProfile.email,
         address1,
         city,
         state,
         zipCode,
         entity: {
            fullName: data.customerEntity.fullName || "fullName",
            contactName: data.customerEntity.contactName || "Contact Name"
         },
         list

      }
   }


   const loadCart = useCallback ( async () => {

      if (!sessionIsAvailable()) {
         history.push('/')
         return
      }
      if (loading) return

      try {
         setLoading(true)
          let response = await api().get("/cart/" + cart) 
          const {data} = response.data
         const collectedCartItems = cartItemsMapper(data)
         let addressesResponse = await api().get("/CustomerShipping/customer/" + data.customerId + "/list") 
         setLoading(false)

          setCartData({
            cartUID: collectedCartItems.cartUID,
            items: collectedCartItems.items,
            address: getDeliveryAddress(data, addressesResponse.data.data),
            summary: cartSummaryMapper(data)
          })

          if (!cartUID) setcartUID(collectedCartItems.cartUID)
   
       } catch (error) {
           const {response} = error

           if (response && response.status && response.status === 401) {
            setModal({type: ModalType.ERROR , message: "Your session has expired."})
            logout()
            return
         }

           dispatch(setTotalCartItems(0))
           setLoading(false)

           if (response && response.data) {
               console.log("ERROR" ,(response.data.messages && response.data.messages.toString()))
               const message = (response.data.messages && response.data.messages.toString()) || (response.data.title) || "Something went wrong"
               if (message !== "Not Found") {
                  setModal({type: ModalType.ERROR, message})
               }

           }
       }

   }, [])

   const placeOrder = async (customerShippingId) => {
      if (!sessionIsAvailable()) {
         history.push('/')
         return
      }
      if (cartUID === "" || customerShippingId == null) return
      
      try {
         let response = await api().post("/order", 
            {
               CartUID: cartUID,
               BillingSameAsDelivery: false,
               customerShippingId
         })

         dispatch(setTotalCartItems(0))
         setProgress(CheckoutProgress.COMPLETED)
         gaPlaceOrder(cartUID)
      } catch (error) {
         const {response} = error
         if (response && response.status && response.status === 401) {
            setModal({type: ModalType.ERROR , message: "Your session has expired."})
            logout()
            return
         }
         if (response && response.data) {
            console.log("ERROR" ,(response.data.messages && response.data.messages.toString()) || response.data.title)
            setModal({type: ModalType.ERROR, message: (response.data.messages && response.data.messages.toString()) || response.data.title || "Something went wrong"})

        }
      }
   }

   const proceedCheckoutHandler = (customerShippingId) => {
      window.scrollTo(0,0)
      
      if (progress === CheckoutProgress.REVIEW) {
         setProgress(CheckoutProgress.CONFIRMATION)
      }

      if (progress === CheckoutProgress.CONFIRMATION) {
         placeOrder(customerShippingId)
         dispatch(setTotalCartItems(0))
         setProgress(CheckoutProgress.COMPLETED)

      }
   }

   useEffect(() => {
      if (!sessionIsAvailable()) {
         history.push("")
         return
      }

      gaPageview("/checkout", 'Checkout ' + cartUID)
      loadCart()
      // setCartItems(cartMockData)

   }, [state.totalItemsInCart])
   return (
      <>
         { modal.type !== "" && <Modal {...modal} /> }
         <Topbar type='checkout' searchPlaceholder="Search by NDC, Product Name or Manufacturer" />
         <RxPlContent>
            <RxPlCheckoutLayout>
               <RxPlCheckoutLayout.Content>
   
                  {
                     progress === CheckoutProgress.COMPLETED && <ThankYou email={cartData.address.email} />
                  }

                  {
                     progress !== CheckoutProgress.COMPLETED && 
                     <>
                  <RxPlCheckoutHeader>
                     <h5>{state.totalItemsInCart} {state.totalItemsInCart > 1 ? "items" : "item"} in your cart<br/>
                     <span>Please confirm your order details below</span>
                     </h5>
                     <Button type="outline" className="no-stretch" onClick={() => history.push("/")}>Return to Search</Button>
                  </RxPlCheckoutHeader>
                  {
                     cartData.items && cartData.items.length > 0 && 
                     <RxPlCheckoutContainer>
                        <RxPlCartList>
              
                           {
                              cartData.items.map ( (item,index) => 
                                 <CartGroup 
                                    key={index}
                                    data={item}
                                    progress={progress}
                                    lockItems={true}
                                    enableTextarea={progress === CheckoutProgress.REVIEW} />
                              )
                           }
              
                        </RxPlCartList>
                        <RxPlCheckoutSummary>

                           
                         
                               <CartSummary 
                               data={cartData.items} 
                               address={cartData.address}
                               summary={cartData.summary}
                               progress={progress} 
                               proceedCheckoutHandler={proceedCheckoutHandler} 
                               buttonLabel={progress === CheckoutProgress.REVIEW ? "Proceed to Checkout" : "Place Your Order"} />
                           

                        </RxPlCheckoutSummary>
                     </RxPlCheckoutContainer>
                  }


                     </>
                  }

               </RxPlCheckoutLayout.Content>
            </RxPlCheckoutLayout>
       

         </RxPlContent>
         <Footer logIn={true} />

      </>
   )
}

Checkout.propTypes = propTypes
Checkout.defaultProps = defaultProps

export default Checkout