import styled from 'styled-components'
import {color} from 'shared/theme'
import {device} from 'shared/layout'

export const RxButterBar = styled.div`
  position: relative;
  witdth: 100%;
  background: ${color.darkSky};
  height: auto;
   display: flex;
   align-items: center;

   .wrapper {
      max-width: 1440px;
      margin: 0 auto;
      top: 0;
      left: 0;
   }
  p {
    color: white;
    margin-bottom: 0;
    padding: 8px 24px;
    text-align: center;

  }

  @media only screen and ${device.sm} {
    height: auto;

      p {
      padding: 8px 48px;
    }
  }

  @media only screen and ${device.lg} {
    height: 40px;

    p {
      padding: 8px 0;
  }
  }
`

