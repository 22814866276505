
export const color = {
   primary: '#4F4F4F',
   indigo: '#092C4C',
   sky: '#317EFF',
   lightSky: '#5780C0',
   darkSky: "#2F79F3",
   blue: '#1F479E',
   warm: '#EE774C',
   success: '#27AE60',
   warning: '#E2B93B',
   error: '#EB5757',
   hover: '#315FA9',
   active: '#7DACF5',
   orange: '#EE774C', 
   white:  '#fff',
   grayPrimary: '#4F4F4F',
   grayLightest: '#E0E0E0',
   grayLight: '#BDBDBD',
   grayMedium: '#828282',
   grayDark: '#333333',
   backgroundSearch: '#F4F5F4',
   backgroundDefault: '#F7F8FC',
   backgroundLighGray: '#f7f8fc',
   backgroundDropdown: '#E5E5E5',
   outlineHover: '#DAE8FC',
   outlineActive: '#7DACF5'
}

export const font = {
   regular: 'font-family: "InterRegular", sans-serif; font-weight: normal;',
   light: 'font-family: "InterLight", sans-serif; font-weight: 300;',
   medium: 'font-family: "InterMedium"; font-weight: 500;',
   bold: 'font-family: "InterBold"; font-weight: normal;',
   size: size => `font-size: ${size}px;`,
}