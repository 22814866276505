import styled from 'styled-components'
import {color, font} from 'shared/theme'
import {device} from 'shared/layout'

export const RxPlSubscribe = styled.div`

   width: 100%;
   height: auto;
   position: relative;
   background: ${color.blue};
   z-index: 10;

   color: white;

   .wrapper {
      width: 100%;
      max-width: 1440px;
      padding: 1.5rem;
      margin: 0 auto;
      display: block;
   }
   p {
      margin: 0 0 1.5rem;
      width: 100%;
      ${font.regular}

      border-right: unset;

      &.fullwidth {
         width: 100%;
         text-align: center;
         border-right: unset;
      }

      a {
         color: inherit;
      }
   }

   .spinner {
      transform: translateY(-140%);
   }

   strong {
      ${font.bold}
   }
   form {
      width: 100%;
      margin: 0;
      padding: 0;
      display: block;
      margin: 0;
      input,
      .form-field,
      button {
         width: 100%;
         flex: unset;
         margin: 0;
      }

      label {
            ${font.regular}
         }

      .form-field, {
         margin: 0 0 1.5rem !important;

         input {
            margin: 0 !important;
            margin-top: 1.5rem;
         }
      } 

      button {
         max-width: unset !important;
         align-self: flex-end;
      }

      input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
         color: ${color.sky} !important;
         opacity: 1; /* Firefox */
       }

       input:-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: ${color.sky} !important;
      }

      input::-ms-input-placeholder { /* Microsoft Edge */
         color: ${color.sky} !important;
      }

      button {
         font-size: 1.25rem;
         max-width: 268px;
         margin-top: 1.5rem;
         ${font.bold}
      }
   }
   .error {
      border-right: unset;
   }
   .error,
   .success {
         width: 100%;
         padding-left: 0;

         h2 {
            ${font.regular}
            font-size: 2.375rem;
            line-height: 2.875rem;
         }
      }
   @media only screen and ${device.sm} {
      display: flex;

      .wrapper {
         display: flex;
         align-items: center;
         padding: 3rem;

      }

      .error,
      .success {
         width: 68%;
         display: flex;
         align-items: center;
         padding-left: 3rem;

      }


      form {
         width: 68%;
         display: flex;
         margin: 0 0 1.5rem 1.5rem;

         .form-field,
         button {
            flex: 1;
         }

         button {
            min-height: 54px;
            max-width: 268px !important;
            font-size: 1.188rem;
            color: ${color.sky}
         }

         .form-field {
            margin: 0 1.75rem 0 1.5rem !important;

            label {
               font-size: 1.25rem;
               margin-bottom: 2.5rem;
               line-height: 1.5rem;
            }
            input {
               min-height: 54px;
               margin: 0 !important;
               padding-left: 1.5rem;
               padding-right: 1.5rem;
            }
         }
      }

      p {
         margin: 0;
         width: 46%;
         padding-top: 1rem;
         padding-bottom: 1rem;
         padding-right: 3rem;
         border-right: 1px solid white;
         font-size: 1.5rem;
         line-height: 1.813rem;
      }
   
   }
`