import React from 'react'
import { RxCarouselItem } from './styles'
import Button from 'shared/components/Button'
import placeholder from 'assets/images/medicine_placeholder.png'


const CarouselItem = props => {


   const onAddToCartHandler = () => {
      const {inventoryId, productId, productUID, ndc, providerId, providerUID, price } = props.data
      const product = {
         inventoryId,
         productId,
         productUID,
         productCode: ndc,
         providerId,
         providerUID,
         quantity: 1,
         price
      }
     
      props.addToCart(product)

   }


   return (
      <RxCarouselItem>
      <RxCarouselItem.Card>
         <RxCarouselItem.Thumbnail>
            
            <img src={props.data.imageFilename != null ? props.data.imageFilename : placeholder} alt="medicine placeholder" />

            {
               props.data.categoryCode === 'INV_SHORTDATED' && 
               <RxCarouselItem.Badge>
                        <p>Short Dated</p>

               </RxCarouselItem.Badge>
            }
         </RxCarouselItem.Thumbnail>
         <div className='product-info'>
            <h6>{props.data.brandName.toLowerCase() === "brand name" || props.data.brandName == null ? props.data.genericName : props.data.brandName} {props.data.dose}</h6>
            <p><span className="sky-text">by {props.data.labeler || ""}</span></p>
               <p>
               {props.data.description} <br/>
               NDC: {props.data.ndc}
               </p>
         </div>
         <Button className="add-to-cart" type="primary" onClick={onAddToCartHandler} >ADD TO CART</Button>

      </RxCarouselItem.Card>
   </RxCarouselItem>

   )
}


export default CarouselItem
