import React, {useState, useEffect, useCallback} from 'react'
import Topbar from 'shared/components/Topbar'
import Footer from 'shared/components/Footer'
import Modal from 'shared/components/Modal'
import Select from 'shared/components/Select'
import CartSummary from 'shared/components/Cart/Summary'
import { RxPlCard } from 'shared/components/Card/style'
import CartBanner from 'shared/components/Cart/Banner'
import {CartGroup, TimeoutMessage} from 'shared/components/Cart'
import {RxPlContent} from 'shared/layout'
import Spinner from 'shared/components/Spinner'
import PropTypes from 'prop-types'
import {ModalType} from 'shared/constants/modal'
import {CheckoutProgress} from 'shared/constants/cart'
import { useCart } from 'shared/hooks/useCart'
import { usePage } from 'shared/hooks/usePage'
import { gaPageview } from 'shared/utils/ga'
import { 
   loadShippingList, 
   setTotalCartItems,
   } from 'shared/contexts/CartContext/reducer'
import { useHistory} from 'react-router-dom'
import useService from 'shared/hooks/useService'

import axios from 'axios'
import {RxPlCartLayout, 
      RxPlCartContainer, 
      RxPlCartList,
   } from './styles'

const propTypes = {
   progress: PropTypes.oneOf([CheckoutProgress.REVIEW, CheckoutProgress.CONFIRMATION, CheckoutProgress.COMPLETED]),
 }

 const defaultProps = {
   progress: CheckoutProgress.REVIEW,
 }

 let request = null

const Cart = props => {
   const [progress, setProgress] = useState(props.progress)
   const [isReset, setReset] = useState(false)
   const {debug, modal, setModal} = usePage()
   const {state, dispatch, loadCart, savedNotes} = useCart()
   const [loading, setLoading] = useState(false)
   const [showTimeoutMessage, setTimeoutMessage] = useState(false)
   const [showMessage, setShowMessage] = useState(false)
   const {sessionIsAvailable, api, logout} = useService()
   const history = useHistory()


   const loadShippingItems = async (callback) => {
      try {

         let response = await api().get("/cart/ship/list")
         const shippingListData = response.data.data
         if (typeof callback === 'function') {
            dispatch(loadShippingList(
               shippingListData.map( it => ({
                  id: it.shippingId,
                  label: it.shippingDescription,
                  value: it.shippingId
               }))
            ))
            callback()
         }

      } catch (error) {
         const {response} = error

         if (response && response.status && response.status === 401) {
            setModal({type: ModalType.Error , message: "Your session has expired."})
            logout()
            return
         }

         if (response && response.data) {
            const errorMesage = (response.data.messages && response.data.messages.toString()) || response.data.detial || response.data.title
            setModal({type: ModalType.Error , message: errorMesage})

        }
      }
   }

   const reset = () => {
      dispatch(setTotalCartItems(0))
      setLoading(false)
      // setCartData(prevState => ({
      //    ...prevState,
      //    original: null,
      //    optimized: null
      // }))
      setModal({type: ""})
   }

   const loadCarts = () => {

      if (!sessionIsAvailable()) {
         history.push("/")
         return
      }

      if (loading) return

      loadCart(
         () => {

            // load optimized after original
            loadCart(
               (success) => {
                  setLoading(false)
               },
               (error) => {
                  if (typeof error.response === 'undefined') {
                     setTimeoutMessage(true)
                  } else {
                     setShowMessage(error.response.status === 400 || error.response.status === 404)
                  }
               },
               "/optimized"
            )
            setLoading(false)
         },
         (error) => {
            if (typeof error.response === 'undefined') {
               setTimeoutMessage(true)
            } else {
               setShowMessage(error.response.status === 400 || error.response.status === 404)
            }
         }
      )
   }


   const refreshCart = useCallback( () => {
      if (loading) return
      setLoading(true)
      setTimeoutMessage(false)
      setModal({type: ModalType.LOADING})
      loadCarts()
   }, [state.cart]);

   const updateShippingOption = async (shippingId) => {

      if (typeof shippingId === "undefined") return
      try {

         if (request) {
            request.cancel("cancel pending request")
         }
         request = axios.CancelToken.source()


         let response = await api().post("/cart/ship/" + shippingId)
         const {data} = response
         if (data.data) {
            refreshCart()
         }

      } catch (error) {
         const {response} = error 

         if (response) {
            if (response.status && response.status === 401) {
               setModal({type: ModalType.ERROR , message: "Your session has expired."})
               logout()
               return
            }

            console.log("ERROR" ,(response && response.data && response.data.messages && response.data.messages.toString()) || response.data.title)
            // setModal({type: ModalType.ERROR, message: response.data.title || "Something went wrong"})

        }
      }
   }

   const deleteItem = async (cart) => {

      try {

         await api().delete("/cart/item/" + cart.cartDetailId)
         refreshCart()

      } catch (error) {
         const {response} = error
         if (response && response.status && response.status === 401) {
            setModal({type: ModalType.ERROR , message: "Your session has expired."})
            logout()
            return
         }

         if (typeof error.response === 'undefined') {
            if (request) {
               request.cancel("cancel ongoing request")
            }
            reset()

            setTimeoutMessage(true)

         } else {
            setShowMessage(error.response.status === 400 || error.response.status === 404)
         }

      }
   }

   const updateQuantity = async(cart) => {

      try {

         if (request) {
            request.cancel("cancel pending request")
         }

         await api().put("/cart/item/" + cart.cartDetailId, {
            productId: cart.productId,
            productUID: cart.productUID,
            productCode: cart.productCode,
            providerId: cart.providerId,
            providerUID: cart.providerUID,
            quantity: parseInt(cart.quantity),
            notes: cart.notes,
            inventoryId: cart.inventoryId
         })

     
         refreshCart()

      } catch (error) {
         const {response} = error


         if (response && response.status && response.status === 401) {
            setModal({type: ModalType.ERROR , message: "Your session has expired."})
            logout()
            return
         }

         if (request) {
            request.cancel("cancel pending request")
         }

         if (response && response.data) {
            console.log("ERROR" ,(response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || error)
         }
      
         if (response) {
            setModal({
               type: ModalType.ERROR, 
               message: response.data.validations["3001"] || response.data.validations["3002"] || response.data.validations["3003"] || response.data.validations["3004"] || "Failed to update quantity.",
               onClose: () => {setReset(false)}
            })
            setReset(true)
         }

      }
   }

   const cartLock = async(cart) => {
      try {

         if (request) {
            request.cancel("cancel pending request")
         }

          const detail =  await api().put("/cart/lock/" + cart.cartDetailId, {
            inventoryId: cart.inventoryId,
            productId: cart.productId,
            productUID: cart.productUID,
            productCode: cart.productCode,
            providerId: cart.providerId,
            providerUID: cart.providerUID,
            isLocked: cart.isLocked,
         })

         refreshCart()

      } catch (error) {
         const {response} = error


         if (response && response.status && response.status === 401) {
            setModal({type: ModalType.ERROR , message: "Your session has expired."})
            logout()
            return
         }

         if (request) {
            request.cancel("cancel pending request")
         }

         if (response && response.data) {
            console.log("ERROR" , (response.data.messages && response.data.messages.toString()) || response.data.title || error)
         }


      }
   }

   const onShippingListChange = useCallback((e) => {
      updateShippingOption(e.target.value)
   }, [])

   const onUpdateQuantity = useCallback((cart) => {
      updateQuantity(cart)
    }, []);

   const onDelete = useCallback( (cart) =>{
      deleteItem(cart)
   }, [])

   const onCartLock = useCallback( (cart) => {
      cartLock(cart)
   }, [])

   const proceedCheckoutHandler = useCallback((cartUID) => {
      window.scrollTo(0,0)
      history.push("/checkout?cart=" + cartUID)
   }, []);

   const onSaveNotes = useCallback((data) => {
      if (loading) return
      setLoading(true)
      setModal({type: ModalType.LOADING})

      savedNotes(data, 
         
         success => {
            setLoading(false)
         },
         error => {
            if (typeof error.response === 'undefined') {
               setTimeoutMessage(true)
            } else {
               setShowMessage(error.response.status === 400 || error.response.status === 404)
            }
         }
         
      )

      setLoading(false)
   },[])

   useEffect(() => {
      if (debug) {
         // setOriginalCart(cartMockData)
         return
      }
      if (!sessionIsAvailable()) {
         history.push("")
         return
      }

      gaPageview("/cart", "Cart")

      loadShippingItems(loadCarts)
      return () => {
         if (request) {
            request.cancel()

         }
      }
   }, [])
   return (
      
      <>
         { modal.type !== "" && <Modal {...modal} /> }
         <Topbar type='checkout' searchPlaceholder="Search by NDC, Product Name or Manufacturer" />
         <RxPlContent>
            <RxPlCartLayout>
               <RxPlCartLayout.Content>

                  {
                     !state.cart.optimized && !state.cart.original && !showMessage && !showTimeoutMessage && modal.type !== ModalType.LOADING &&
                     <Spinner />
                  }
                  {
                     showTimeoutMessage &&
                     <TimeoutMessage onRefresh={refreshCart} />
                  }
                  {
                     showMessage && 
                     <h5>Your Cart is Currently Empty!</h5>

                  }
                  {
                     progress !== CheckoutProgress.COMPLETED && 
                  <>
      
                  {
                     state.cart && state.cart.optimized && state.cart.optimized.summary.savingsStr && (state.cart.optimized.summary.savingsStr !== "0.00") && 
                     <CartBanner savingsStr={state.cart.optimized.summary.savingsStr} />

                  }

                  {
                     state.cart.original && state.cart.original.items.length > 0 && state.shipping && state.shipping.option && state.shipping.list && state.shipping.list.length > 0 &&
                     <RxPlCard className="shipping-information">
                        <h6>Your current shipping method for this order is</h6>
                        <div>
                           <Select data={state.shipping.list} onChange={onShippingListChange} value={state.shipping.option}/>
                           <p>Updating your shipping method may change the total cost of your order.</p>
 
                              <p><strong>Cutoff Times:</strong> <br/>
                              Capital: 2:30pm PST <br/>
                              South Pointe: 5pm PST <br/>
                              2nd Source:  3pm PST <br/>
                              Real Value Products: 3pm
                              </p>
                     
                        </div>
                      </RxPlCard>
                  }
                  <RxPlCartContainer >
                     {
                        state.cart && state.cart.original && state.cart.original.items && state.cart.original.items.length > 0 && 
                        <RxPlCartList>
                           <h6>YOUR SELECTED ITEMS</h6>
                           <CartSummary 
                              data={state.cart.original.summary} 
                              progress={CheckoutProgress.CART} 
                              proceedCheckoutHandler={(cartUID) => proceedCheckoutHandler(cartUID)} 
                              buttonLabel="CHECKOUT WITH MY ORIGINAL CART" />

                           {
                              state.cart.original.items.map ( (item,index) => 
                                 <CartGroup 
                                    key={index}
                                    data={item}
                                    isReset={isReset}
                                    progress={CheckoutProgress.REVIEW} 
                                    onUpdateQuantity={onUpdateQuantity}
                                    onSaveNotes={onSaveNotes}
                                    onCartLock={onCartLock}
                                    onDelete={onDelete}
                                  />
                              )
                           }
        
                        </RxPlCartList>
                     }

                      {
                        state.cart && state.cart.optimized && state.cart.optimized.items && state.cart.optimized.items.length > 0 && 
                        <RxPlCartList className="optimized-cart" style={{height: state.cart.optimized.summary.savingsStr === '0.00' ? 'max-content' : null }}>
                           <h6 className="c-white">YOUR OPTIMIZED CART</h6>
                           <>
                              {
                                 state.cart.optimized.summary.savingsStr !== "0.00" ? 
                                 <> 
                                       <CartSummary
                                          title="YOUR OPTIMIZED CART COSTS" 
                                          data={state.cart.optimized.summary}
                                          progress={CheckoutProgress.CART} 
                                          proceedCheckoutHandler={proceedCheckoutHandler} 
                                          buttonLabel="CHECKOUT AND SAVE WITH MY OPTIMIZED CART" />

                                       {
                                          state.cart.optimized.items.map ( (item,index) => 
                                             <CartGroup 
                                                key={index}
                                                data={item}
                                                progress={progress}
                                                onSaveNotes={onSaveNotes}
                                                lockItems={true}
                                                />
                                          )
                                       }

                                 </>

                                 : 
                                 <>
                                    <CartSummary
                                       noSavings={false}
                                       />
                                 </>
                              }
                           </>

              
                        </RxPlCartList>
                     } 
 
                  </RxPlCartContainer>
  
                     </>
                  }

               </RxPlCartLayout.Content>
            </RxPlCartLayout>
       

         </RxPlContent>
         <Footer logIn={true} />

      </>
   )
}

Cart.propTypes = propTypes
Cart.defaultProps = defaultProps

export default React.memo(Cart)