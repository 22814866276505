import Hero from 'shared/components/Hero'
import Footer from 'shared/components/Footer'
import Button from 'shared/components/Button'
import {color} from 'shared/theme'
import {RxPlHomeLayout} from './style'
import { usePage } from 'shared/hooks/usePage'
import { ModalType } from 'shared/constants/modal'
import Subscribe from 'shared/components/Subscribe'
import { useEffect, useRef, useState } from 'react'

import basket from "./images/bucket.jpeg"
import balanceScales from "./images/scale.jpeg"
import variedPillBottles from "./images/bottles.jpeg"


const HomePage = (props) => {

   const offeringsRef = useRef(null)
   const discoverRef = useRef(null)
   const processRef = useRef(null)
   const heroRef = useRef(null)
   const {setPage,setModal} = usePage()
   const [location, setLocation] = useState("")
   const handleSignup = () => {
      setPage("signup")
   }

   const handleRequestAccess = () => {
      setModal({type: ModalType.REQUEST_ACCESS })

   }

   const handleHashChange = () => {
      setLocation(window.location.hash)
      if (window.location.hash === "#request-access") {
         handleRequestAccess()
      }
   }


   const options = {
      rootMargin: "0px",
      threshold: 0.5
   }

   useEffect(() => {
      window.addEventListener("hashchange", handleHashChange);
      handleHashChange()
      let observer = new IntersectionObserver(function (entries) {
         entries.forEach(function (entry) {
            if (entry.isIntersecting) {
               if (window.scrollY === 0) return
               if (entry.target.id === "hero") {
                  window.location.hash = ''

               } else {
                  if (location !== "#request-access") {
                     window.location.hash = '#' + entry.target.id;
                     setLocation(entry.target.id)

                  }
               }
            } 
         });
      }, options);


      heroRef.current && observer.observe(heroRef.current)
      discoverRef.current && observer.observe(discoverRef.current)
      offeringsRef.current && observer.observe(offeringsRef.current)
      processRef.current && observer.observe(processRef.current)

      return () => {
         offeringsRef.current && observer.unobserve(offeringsRef.current)
         discoverRef.current && observer.unobserve(discoverRef.current)
         processRef.current && observer.unobserve(processRef.current)
         heroRef.current && observer.unobserve(heroRef.current)
         window.removeEventListener("hashchange", handleHashChange);

      }
   }, [])

   return (
      <>

         <RxPlHomeLayout ref={heroRef} id="hero">
            <Hero />
         </RxPlHomeLayout>

         <RxPlHomeLayout ref={offeringsRef} background={color.white} id="our-offerings" className='rxplace-offerings'>
            <RxPlHomeLayout.Content>
               <div className='center-align'>
                  <h2>RxPlace Offerings</h2>
                  <p>RxPlace is the first and only choice for secondary pharmaceutical procurement, connecting you to multiple key wholesalers and providing reliable access to over 20,000 medications and counting.</p>
               </div>

               <ul>
                  <li>
                     <h3>20,000+<span>medications</span></h3>
                     <p>including hard-to-find and new generics, are added regularly.</p>
                  </li>
                  <li>
                     <h3>NABP<span>accredited</span></h3>
                     <p>wholesalers meet your preferences and offer the flexibility to choose equivalent products.</p>
                  </li>

                  <li>
                     <h3>Enhanced<span>checkout</span></h3>
                     <p>identifies price reductions you may have missed, maximizing your savings.</p>
                  </li>
               </ul>

               <Button type="blue" onClick={handleRequestAccess}>START BUYING NOW</Button>

            </RxPlHomeLayout.Content>
         </RxPlHomeLayout>

         <RxPlHomeLayout ref={discoverRef} background={color.white} id="discover-the-product" className='discover-the-product'>
            <RxPlHomeLayout.Content textAlign="center">

               <div className='content-wrapper'>
                  <div className='center-align'>
                     <h2>The Power of AI On Your Side</h2>
                     <p>Maintain control while RxPlace serves as your virtual buyer and procurement partner.</p>
                  </div>

                  <RxPlHomeLayout.List display="flex" className="flex-reversed">
                     <RxPlHomeLayout.List.Item className="text">
                        <p>RxPlace evaluates availability, offers bundling suggestions, protects you from out-of-stock situations, and maximizes your profit&nbsp;margins.</p>
                     </RxPlHomeLayout.List.Item>
                     <RxPlHomeLayout.List.Item className="image">
                        <img src={basket} alt="Save Money" />
                     </RxPlHomeLayout.List.Item>
                  </RxPlHomeLayout.List>

                  <RxPlHomeLayout.List display="flex">
                     <RxPlHomeLayout.List.Item className="image">
                        <img src={balanceScales} alt="Balance Scales" />
                     </RxPlHomeLayout.List.Item>
                  
                     <RxPlHomeLayout.List.Item className="text">
                        <p>We optimize order quantities to reduce your costs and ensure that your patients' needs are consistently met.</p>
                     </RxPlHomeLayout.List.Item>
                  </RxPlHomeLayout.List>


                  <RxPlHomeLayout.List display="flex" className="flex-reversed">
                  <RxPlHomeLayout.List.Item className="text">
                        <p>Automatically get recommendations for equivalent medication options available across multiple wholesalers, all in one simple to use interface.</p>
                     </RxPlHomeLayout.List.Item>

                     <RxPlHomeLayout.List.Item className="image">
                        <img src={variedPillBottles} alt="Varied Pill bottles" />
                     </RxPlHomeLayout.List.Item>
                  </RxPlHomeLayout.List>

                  <Button type="primary" className="center-align-btn" onClick={handleRequestAccess}>GET STARTED NOW</Button>

               </div>




            </RxPlHomeLayout.Content>  
         </RxPlHomeLayout>


         <RxPlHomeLayout ref={processRef} background={color.white} id="simple-process" className="simple-process" >
            <RxPlHomeLayout.Content>
               <div className='content-wrapper'>
                  <div className='center-align'>
                     <h2>Thoughtfully Simple</h2>
                     <p>RxPlace provides a user-friendly platform, enabling you to effortlessly find the best products to meet your community's needs.</p>
                  </div>

                  <ul>
                     <li>
                        <div className='counter-wrapper'>
                           <h3>1</h3>
                        </div>
                        <h4>Easy access<span>to more than<br/>20,000 products</span></h4>
                     </li>

                     <li>
                     <div className='counter-wrapper'>
                           <h3>2</h3>
                        </div>
                        <h4>Lowest prices<span>on the exact<br/>products you need</span></h4>
                     </li>
                     <li>
                     <div className='counter-wrapper'>
                           <h3>3</h3>
                        </div>
                        <h4>Optimized cart<span>ensures you save the<br/>most on every order</span></h4>
                     </li>
                  </ul>

                  <Button type="tertiary" className="center-align-btn" onClick={handleRequestAccess}>TAKE THE FIRST STEP</Button>

               </div>


            </RxPlHomeLayout.Content>

         </RxPlHomeLayout>

         <Subscribe/>


         <Footer className="light" />



      </>
   )
}


export default HomePage