import { useState, useEffect} from 'react'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import PropTypes from 'prop-types';
import Icon from 'shared/components/Icon'
import { IconRegular } from 'shared/constants/icons'

import {
      RxPlCartItem,
      RxPlProductThumbnail,
      RxPlProductDescription,
      RxPlCartItemColumn,
      RxPlRow
   } from './styles'
import placeholder from 'assets/images/medicine_placeholder.png'
import closeIcon from 'assets/icons/x-icon.svg'


const propTypes = {
   lock: PropTypes.bool,
}

const defaultProps = {
   lock: false
}

const CartItem = props => {

   const [quantity, setQuantity] = useState(props.data.quantity)
   const [lock, setLock] = useState(props.data.isLocked)
   const onChange = e => {
      if (e.target.value.trim() === '' || e.target.value.length === 0 || e.target.value === '0') return
      setQuantity(e.target.value)
   }

   const onSearchEnter = e => {
      const key = e.code || e.keyCode || e.charCode 

      if ((key === "Enter" || key === "NumpadEnter" || key === 13) && quantity.length !== 0) {
         props.onUpdateQuantity({
            cartDetailId: props.data.cartDetailId,
            quantity: e.target.value,
            productCode: props.data.productCode,
            productId: props.data.productId,
            inventoryId: props.data.inventoryId,
            productUID: props.data.productUID
         })
      }

      if (key === 8 || key === 46 ||  key === 67 || key === 'Backspace' || key === 'Delete') {
         setQuantity ( prev => prev && typeof prev.slice === 'function' && prev.slice(0, -1))
      }

   }

   useEffect(() => {
      if (props.isReset) {
         setQuantity(props.data.quantity)
      }
      setLock(props.data.isLocked)
   },[props.isReset, props.data.isLocked])
   
   return (
      <>
         <RxPlCartItem>
            <RxPlRow>
               <RxPlProductThumbnail>
                  <img src={props.data.imageFilename ? props.data.imageFilename : placeholder} alt="thumbnail"/>
                  {
                     props.data.categoryCode === 'INV_SHORTDATED' && 
                     <RxPlProductThumbnail.Badge>
                        <p>Short Dated</p>
                     </RxPlProductThumbnail.Badge>
                  }
               </RxPlProductThumbnail>
               <RxPlProductDescription>
                  <h6>{props.data.brandName || "Brand Name"} {props.data.dose}<br/><span>by {props.data.labeler}</span></h6>
                  <p>{props.data.productQuantity || "Production Description"}<br/>
                   NDC: {props.data.productCode || "Product"}
                   {
                      props.data.expirationDate && 
                      <>
                        <br/>
                        Expires: {props.data.expirationDate}
                      </>
                   }
                   <br/>
                   Unit Cost: ${props.data.priceStr}
                   </p>

                   {
                      props.onCartLock && 
                      <Icon type={props.data.isLocked ? IconRegular.LOCK : IconRegular.UNLOCK} onClick={ () => {props.onCartLock({
                        inventoryId: props.data.inventoryId,
                        productId: props.data.productId,
                        productUID: props.data.productUID,
                        productCode: props.data.productCode,
                        providerUID: props.data.providerUID,
                        cartDetailId: props.data.cartDetailId,
                        isLocked: !lock,
                     })}} />
                   }
                   
                  <RxPlRow className="flex">

                     <RxPlCartItemColumn className="text-align-center">
                        <p>QTY</p>
                        {
                           props.lock ? 
                           <h6>{props.data.quantity}</h6>
                           : <Input value={props.isReset ? props.data.quantity : quantity} onChange={onChange} onKeyDown={onSearchEnter} tabIndex={-1} type="number" min="0" max="200" />

                        }
                     </RxPlCartItemColumn>

                     <RxPlCartItemColumn className="text-align-center">
                        <p>Total</p>
                        <h6>${props.data && props.data.grossAmountStr ? props.data.grossAmountStr : "325"}</h6>
                     </RxPlCartItemColumn>
                  </RxPlRow>
               </RxPlProductDescription>
            </RxPlRow>

            {
               !props.lock &&            
               <Button onClick={ () => props.onDelete({cartDetailId: props.data.cartDetailId, wholesaler: props.data.wholesaler})}><img src={closeIcon} /></Button>

            }

         </RxPlCartItem>
      </>
   )
}

CartItem.propTypes = propTypes
CartItem.defaultProps = defaultProps

export default CartItem