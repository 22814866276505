import {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import {RxPlDaysFilter} from './styles'
import {RxPlInformationCard} from '../styles'
import useService from 'shared/hooks/useService'
import Button from 'shared/components/Button'

const History = props => {
   const {api} = useService()
   const [isFetching, setIsFetching] = useState(false)
   // const [filters, setFilters] = useState(props.filters)
   // const [fromDate, setFromDate] = useState(new Date().toISOString().substring(0, 10))
   const [order, setOrder] = useState(null)
   const [startDate, setStartDate] = useState(new Date(moment().format("MM") + "-" + "01" + "-" + moment().format("YYYY")).toISOString().substring(0,10))
   const [endDate, setEndDate] = useState(new Date(moment().format("YYYY-MM-DD")).toISOString().substring(0, 10))
   const inputStartDate = useRef(null)
   const inputEndDate = useRef(null)

   const getOrderSummary = async () => {
      if (isFetching) return
      try {
         setIsFetching(true)
         let response = await api().post("/order/type/totals",  
            {
               dateStart: startDate,
               dateEnd:  endDate
            }

         )
         if (response.data && response.data.data && response.data.data.orderSummary &&  response.data.data.orderSummary.length) {
            setOrder({
               summary: response.data.data.orderSummary,
               total: response.data.data.orderSummaryTotal
            })

         }
         setIsFetching(false)

      } catch (error) {
         setIsFetching(false)

         if (error && error.response && error.response.status && error.response.status === 401) {
            // setModal({type: ModalType.ERROR , message: "Your session has expired."})
            // logout()
            // return
         }
         console.log("error " ,error.response)
         // setErrorMessage(response.data.title)

      }
   }

   const onChangeDateHandler = (e) => {
      // setFromDate(moment(e.target.value).format("YYYY-MM-DD"))
      console.log(e.target.name)
      // getOrderSummary(moment(e.target.value).format("YYYY-MM-DD"))
      e.target.name === "start" && setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
      e.target.name === "end" && setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
   }

   const onSubmitHandler = (e) => {
      getOrderSummary()
   }

   useEffect(()=> {
      // setFilters(props.filters)
      // inputStartDate.current.value = moment().toDate()
      // console.log(inputStartDate)


      const yesterday = moment().add(-1, 'days')

      if (inputEndDate.current) {
         inputEndDate.current.setAttribute("max", moment().format("YYYY-MM-DD"))
      }

      if (inputStartDate.current) {
         inputStartDate.current.setAttribute("max", yesterday.format("YYYY-MM-DD"))
      }
   },[])
   
   return (
      <>
         <RxPlDaysFilter>
            <p><strong>Show from</strong></p> 
            <div className='filter-item'>
               <form>
                  <label>Start</label>
                  <input ref={inputStartDate} type="date" name="start" onChange={onChangeDateHandler}  defaultValue={startDate} />
               </form>
            </div>

            <div className='filter-item'>
               <form>
                  <label>End</label>
                  <input ref={inputEndDate} type="date" name="end" onChange={onChangeDateHandler}  defaultValue={endDate} />
               </form>
            </div>

            <div className='filter-item'>
               <Button type="primary" onClick={onSubmitHandler} disabled={isFetching}>-></Button>
            </div>

         </RxPlDaysFilter> 
          <RxPlInformationCard>
            <RxPlInformationCard.ItemWrapper>

               <RxPlInformationCard.OrderItem>
                  <RxPlInformationCard.OrderItemHeader>                  
                     Date
                  </RxPlInformationCard.OrderItemHeader>
                  {
                     order && order.summary.map ( (summary, index) => {
                        return (
                           <p key={index}>{summary.orderDateStr}</p>
                        )
                     })
                  }
               </RxPlInformationCard.OrderItem>

               <RxPlInformationCard.OrderItem>
                  <RxPlInformationCard.OrderItemHeader>                  
                     Generic
                  </RxPlInformationCard.OrderItemHeader>
                  {
                      order && order.summary.map ( (summary,index) => {
                        return (
                           <p key={index}>${summary.genericStr}</p>
                        )
                     })
                  }
               </RxPlInformationCard.OrderItem>

               <RxPlInformationCard.OrderItem>
                  <RxPlInformationCard.OrderItemHeader>                  
                     Brand
                  </RxPlInformationCard.OrderItemHeader>
                  {
                      order && order.summary.map ( (summary,index) => {
                        return (
                           <p>${summary.brandStr}</p>
                        )
                     })
                  }
               </RxPlInformationCard.OrderItem>

               <RxPlInformationCard.OrderItem>
                  <RxPlInformationCard.OrderItemHeader>                  
                  Totals
                  </RxPlInformationCard.OrderItemHeader>
                  {
                      order && order.summary.map ( (summary,index) => {
                        return (
                           <p key={index}>${summary.totalStr}</p>
                        )
                     })
                  }

               </RxPlInformationCard.OrderItem>
            </RxPlInformationCard.ItemWrapper>

            <RxPlInformationCard.ItemWrapper>

            <RxPlInformationCard.OrderItem>
               <RxPlInformationCard.OrderItemHeader>                  
               </RxPlInformationCard.OrderItemHeader>

            </RxPlInformationCard.OrderItem>

            <RxPlInformationCard.OrderItem>
               <RxPlInformationCard.OrderItemHeader>                  
                  
               </RxPlInformationCard.OrderItemHeader>
               {
                  <p>${order && order.total && order.total.genericTotalStr}</p>

               }
            </RxPlInformationCard.OrderItem>

            <RxPlInformationCard.OrderItem>
               <RxPlInformationCard.OrderItemHeader>                  
                  
               </RxPlInformationCard.OrderItemHeader>
               <p>${order && order.total && order.total.brandTotalStr}</p>

            </RxPlInformationCard.OrderItem>

            <RxPlInformationCard.OrderItem>
               <RxPlInformationCard.OrderItemHeader>                  
               
               </RxPlInformationCard.OrderItemHeader>
               <p>${order && order.total && order.total.grossTotalStr}</p>


            </RxPlInformationCard.OrderItem>
            </RxPlInformationCard.ItemWrapper>

         </RxPlInformationCard>  
      </>
   )
}


export default History