
import React, {forwardRef} from 'react'
import {RxButterBar} from './styles'
const ButterBar = forwardRef( (props, ref) => (
   <RxButterBar {...props} ref={ref}>
      <div className='wrapper'>
      <p>{props.children}</p>

      </div>
    </RxButterBar>
))

export default ButterBar
 