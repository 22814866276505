import React, { useState, useEffect } from 'react'
import Logo from 'shared/components/Logo'
import Button from 'shared/components/Button'
import ButterBar from '../ButterBar'
import Toolbar from 'shared/components/Toolbar'
import Search from 'shared/components/Search'
import {RxPlLayout} from 'shared/layout'
import {ModalType} from 'shared/constants/modal'
import {AuthenticationType} from 'shared/constants/authentication'
import {RxPlFixedLayout, RxPlTopbar, RxPlSearchLayout, RxPlLinkGroupAlignRight, RxPlNav, RxPlHamburgerMenu} from './style'
import PropTypes from 'prop-types'
import { usePage } from 'shared/hooks/usePage'
import { useCart } from 'shared/hooks/useCart'
import Cookies from 'js-cookie'

const propTypes = {
   type: PropTypes.oneOf(['logout', 'login', 'onboarding', 'checkout', 'cart', 'results', 'account', 'admin']),

}

const defaultProps = {
   type: 'logout',
 }

 function useLocationHash() {
   const [hash, setHash] = useState(window.location.hash);
   window.addEventListener("hashchange", (e) => {
      setHash(window.location.hash)
   });

   return {
      hash,
      setHash
   };
 }

const Topbar = props => {
   const {setPage, setModal, isAdmin, setAdminAuthentication} = usePage()
   const {state} = useCart()
   const [showMenu, setShowMenu] = useState(false)
   // const [hash, setHash] = React.useState(() => window.location.hash);
   const {hash, setHash} = useLocationHash()

   const handleSignup = () => {
   //   setModal({type: ModalType.AUTHENTICATION, data: {authenticationType: AuthenticationType.SIGNUP }})
      setPage("signup")

      
   }

   const handleSignin = () => {
      setModal({type: ModalType.AUTHENTICATION, data: {authenticationType: AuthenticationType.SIGNIN }})
   }

   const goHome = () => {
      setPage('')
      setHash("")
   }

   const goHomeAdmin = () => {
      setPage('admin')
   }

   const handleAdminSignOut = () => {
      Cookies.remove('admin_token')
      localStorage.clear()
      setAdminAuthentication(false)
      setPage("admin")
   }

   const handleShowMenu = () => {
      setShowMenu(!showMenu)
   }

   const hashChangeHandler = () => {
      console.log("hashChanged")
      // setHash(window.location.hash);
   }

   return(
      <RxPlFixedLayout {...props}>
         {
            props.children && props.type==='logout' && <ButterBar>{props.children}</ButterBar>

         }
         <RxPlLayout.Content className="no-vertical-paddings">
            <RxPlTopbar>
               <Logo onClick={props.type === 'admin' ? goHomeAdmin : goHome} />


               {
                  (props.type === 'checkout' || props.type === 'results' || props.type ==='account')
                   &&  
                  <RxPlSearchLayout><Search term={props.term} placeholder={props.searchPlaceholder || "Search"}/></RxPlSearchLayout>        
               }


               {
                  (props.type === 'login' || props.type === 'results' || props.type === 'checkout' ||  props.type ==='account') &&
                  <RxPlLinkGroupAlignRight>
                     <Toolbar badgeCount={state.totalItemsInCart} />
                  </RxPlLinkGroupAlignRight>
               }

               {
                  props.type === 'logout' && 
                  <>
                  <div className={`logout-menu-container${showMenu ? ' show' : ''}`}>
                  <div className='logout-menu'>
                     <RxPlNav className='menu'>
                        <li><a className={`${hash === '#our-offerings' ? 'active' : ''}`} href="#our-offerings">Our Offerings</a></li>
                        <li><a className={`${hash === '#discover-the-product' ? 'active' : ''}`} href="#discover-the-product">Discover the Product</a></li>
                        <li><a className={`${hash === '#simple-process' ? 'active' : ''}`} href="#simple-process">Simple Process</a></li>
                     </RxPlNav>       

                     <RxPlLinkGroupAlignRight className='signin-up-btn'>
                        <Button onClick={handleSignin} type='outline'>Log in</Button>
                        <Button onClick={handleSignup} type='primary'>Sign up</Button>
                     </RxPlLinkGroupAlignRight>   

       
                     </div>

                  </div>
                  <RxPlHamburgerMenu onClick={handleShowMenu} className={`${showMenu ? 'close' : ''}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                     </RxPlHamburgerMenu> 
                  </>

               }

               {
                  props.type === 'admin' &&  isAdmin &&
                  <RxPlLinkGroupAlignRight>
                     <Button onClick={handleAdminSignOut} type='primary'>Log out</Button>
                  </RxPlLinkGroupAlignRight>

               }


            </RxPlTopbar>
         </RxPlLayout.Content>

      </RxPlFixedLayout>

   )
}
 
Topbar.propTypes = propTypes
Topbar.defaultProps = defaultProps


export default Topbar