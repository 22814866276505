import styled, {css} from 'styled-components'
import {RxPlLinkGroup, device} from 'shared/layout'
import {color, font} from 'shared/theme'


export const RxPlFixedLayout = styled.div`
   position: fixed;
   width: 100%;
   z-index: 1000;
   background: white;

   ${
    props => props.type === 'login' && 
    css`
      background: ${color.backgroundDefault};
    ` 
  }

  .logout-menu-container {
      width: 100%;

      &.show {

         position: absolute;
         width: calc(100% + 48px);
         margin: 0 -24px;
         .logout-menu {
               display: block;
               position: relative;
               top: 140px;
               padding: 20px 24px 24px;
               box-shadow: 0px 4px 5px rgba(0,0,0,0.05);

               .menu {
                  display: block;

                  li {
                     margin-bottom: 16px;
                  }
               }
               .signin-up-btn {
                  display: block;

               }
         }
      }

  }
  .logout-menu {
      position: relative;
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
      width: 100%;
      background: white;   

      justify-content: center;

  }



${
    props => 
    (props.type === 'logout' || 
    props.type === 'results' ||
    props.type === 'onboarding' ||
    props.type === 'checkout') && 
    css`
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.17);
    ` 
  }

`

export const RxPlTopbar = styled.div`
   width: 100%;
   height: 80px;
   display: flex;
   align-items: center;
  position: relative;

   .lock {
      margin-left: 7%;
      i {
         margin-right: 8px;
      }
   }
   width: 100%;

`

export const RxPlLinkGroupAlignRight = styled(RxPlLinkGroup)`
   margin-left: auto;
   display: none;

   button {
      width: auto;
      padding: 10px 16px;
   }
   @media only screen and ${device.sm} {
      display: block;

      button {
         padding: 16px;
      }
   }
`

export  const RxPlSearchLayout = styled.div`
   width: 100%;
   margin: 0;

   .flex {
      display: flex;
      align-items: center;

      p {
         margin: 0;
      }
   }

   .align-right {
      margin-left: auto;
   }

   button {
      color: ${color.sky};
   }

   @media only screen and ${device.sm} {
 
      width: 70%;
      margin: 0 auto;

      .search-input {
         max-width: unset;

         input {
            max-width: unset;
            padding: 16px 80px 16px 32px;
         }
      }
   }

`

export const RxPlNav = styled.ul`
   list-style: none;
   display: none;
   gap: 8%;
   margin-left: 0;
   width: 60%;
   margin-bottom: 32px;
   
   + {
		.signin-up-btn {
         margin-left: unset;
		}
	}

   a {
      position: relative;
      text-decoration: none;
      color: ${color.blue};
      ${font.bold};
      &:hover {
         color: ${color.sky};
      }

      &.active {
         pointer-events: none;
         color: ${color.sky};

         &:after {
            position: absolute;
            content: "";
            display: block;
            left: 0;
            bottom: -0.25rem;
            width: 100%;
            height: 2px;
            background: ${color.sky};
         }
      }
   }



   @media only screen and ${device.sm} {
      display: flex;
      justify-content: center;
      gap: 10%;
      margin: 0 auto;
   }
`

export const RxPlHamburgerMenu = styled.div`
   position: relative;
   display: block;
   width: 33px;
   margin-left: auto;
   cursor: pointer;
   span {
      width: 100%;
      height: 4px;
      display: block;
      background: ${color.sky};
      margin-bottom: 6px;
      margin-top: 6px;
   }

   &.close {
      span {
         &:nth-child(1) {
            position: absolute;
            top: 0; 
            left: 0;
            transform: rotate(45deg);
         }

         &:nth-child(2) {
            display: none;
         }

         &:nth-child(3) {
            transform: rotate(-45deg);
         }
      }
   }

   @media only screen and ${device.sm} {
      display: none;

   }
`