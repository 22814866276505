import React, { forwardRef } from 'react'
import Input from 'shared/components/Input'
import Select from 'shared/components/Select'
import Checkbox from 'shared/components/Checkbox'

import {
   RxPlFormLayout, 
   RxPlFormGroup,
   } from './styles'


const Form = forwardRef( (props,ref) => (

   <RxPlFormLayout {...props} ref={ref}>
      {props.children}
   </RxPlFormLayout>

))


Form.Field = forwardRef( (props, ref)  => {
   const {type, placeholder, data, label, value, id, name, onChange, defaultValue, readonly, className} = props
   const inputProps = {
      type: type || 'text',
      placeholder,
      data,
      label,
      name,
      id,
      value,
      defaultValue,
      readonly,
      onChange,
      className
   }

   const checkboxProps = {
      label,
      name,
      id,
      onChange
   }
   return (
      <RxPlFormGroup className={className}>
         {
            props.type !== "checkbox" && props.label && <label>{props.label}</label>
         }
    
         {
            type === 'select' ? <Select {...inputProps} ref={ref}/> 
            : type === 'checkbox' ? <Checkbox {...checkboxProps} /> : <Input {...inputProps} ref={ref} />
         }
      </RxPlFormGroup>  
   )

   })

export default Form
