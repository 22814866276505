import {useEffect} from 'react'
import Modal from 'shared/components/Modal'
import Topbar from 'shared/components/Topbar'
import HomePage from './HomePage'
import HomeScreen from './HomeScreen'

import {RxPlContent} from 'shared/layout'
import useService from 'shared/hooks/useService'
import { setTotalCartItems } from 'shared/contexts/CartContext/reducer'
import Zendesk from "react-zendesk"
import { useCart } from 'shared/hooks/useCart'
import { usePage } from 'shared/hooks/usePage'
import { gaPageview } from 'shared/utils/ga'
const ZENDESK_KEY = "09d05dfd-0936-4856-963c-57816b74a094"


const setting = {
   color: {
     theme: "#ccc"
   },
   launcher: {
     chatLabel: {
       "en-US": "Chat with sales"
     }
   },
   contactForm: {
     fields: [
       { id: "description", prefill: { "*": "My pre-filled description" } }
     ]
   }
 }

const Home = () => {

   const {authenticated, setAuthentication, modal} = usePage()
   const {dispatch} = useCart()
   const {getCustomer, loadCart} = useService()

   useEffect(()=>{
      let mounted = true

      if (mounted) {
         getCustomer(
            () => {
               setAuthentication(true)
            },
            error => {
               console.log(error)
               setAuthentication(false)
            }
         )

         if (authenticated) {
            loadCart( it => {
               if (it && it.data && it.data.totalItemsInCart) {
                  dispatch(setTotalCartItems(it.data.totalItemsInCart))
               }
            })
         } else {
            gaPageview("/", "Home")
         }
      }

      return () => mounted = false
   },[authenticated])


   useEffect(()=> {

   }, [])

   return (
      <>
         { modal.type !== "" && <Modal {...modal} /> }
         <Topbar type={authenticated ? 'login' : 'logout'}>
            <p>Be one of the first to join this secondary procurement community and <strong>receive a $25 Amazon gift card with your first order.</strong></p>
         </Topbar>
         <RxPlContent>
            { (authenticated )? <HomeScreen withPicks={true}/> : <HomePage /> }
         </RxPlContent>
         <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => console.log('is loaded')} />

      </>
   )
}


export default Home