import styled from 'styled-components'
import {device} from 'shared/layout'
import {font, color} from 'shared/theme'
import solidIcon from 'assets/icons/select_down_icon.svg'

export const RxPlSearch = styled.div `
   position: relative;
   width: 100%;
   max-width: 780px;
   input {
      margin: 0;
      width: 100%;
   }

   .search-btn {
      position: absolute;
      right: 16px;
      top: 58%;
      transform: translateY(-50%);
   }

   .clear-btn {
      position: absolute ;
      width: 24px;
      height: 24px;
      top: 58%;
      right: 40px;
      transform: translateY(-50%);
   }

   .no-result {
      ${font.bold};
   }

   .shipping-options {
      position: absolute;
      min-width: 188px;
      right: 60px;
      font-size: 16px;
      ${font.bold};
      padding: 8px 20px;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background: ${color.white} url(${solidIcon}) no-repeat !important;
      background-position: 88% center !important;
   }

`

export const RxPlResults = styled.div`
   position: fixed;
   left: 50%;
   transform: translateX(-50%);
   width: 90%;
   top: 60px;
   overflow: auto;
   background: none;
   box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
   z-index: 100;
   > div {
      width: 100%;
      max-height: 380px;
      overflow-y: auto;
   }
   ul {
      list-style: none;

      li {
         padding: 4px;
         margin-bottom: 8px;
         cursor: pointer;

         &.not-priority  {
            color: ${color.grayMedium}
         }

         &.no-inventory-msg {
            padding-bottom: 10px;
            border-bottom: 1px solid  ${color.grayMedium};
            ${font.bold}
         }

         &.load-more-results {
            border-top: 1px solid  ${color.grayMedium};
            color: ${color.sky};
            margin-top: 16px;
            button {
               color: ${color.sky};
               margin-left: 0;
               padding-left: 0;
            }
         }
      }


  
      >.not-priority {
         border-top: 1px solid  ${color.grayMedium};
         padding-top: 10px;

         ~ {
            .not-priority {
               border-top: unset;
               padding-top: unset;
            }
		   }
      }

   }

   @media only screen and ${device.sm} {
      width: 100%;
      position: absolute;

   }
`