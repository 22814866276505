import {useContext} from 'react'
import axios from 'axios'
import {urls} from 'shared/constants/api'
import {PageContext} from 'shared/contexts/PageContext'
import {parseJwt} from 'shared/utils/index'
import { useHistory } from 'react-router-dom'
import { ModalType } from 'shared/constants/modal'
import Cookies from 'js-cookie'


const API_URL = urls.PRODUCTION_API_URL
const ADMIN_API_URL = urls.PRODUCTION_ADMIN_API_URL

export default function useService() {

  const history = useHistory()
  const {
    customer, setCustomer, setAuthentication, debug, setModal
  }  = useContext(PageContext)


    const api = (token) => axios.create({
        baseURL: API_URL,
        timeout: 1000 * 10,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token || Cookies.get('token' || localStorage.getItem("token"))}`,
        },
        error: {
            code: 'INTERNAL ERROR',
            message: 'Something went wrong.',
            status: 503,
            data: {},
        }
    })

    const adminAPI = (token) => axios.create({
      baseURL: ADMIN_API_URL,
      timeout: 1000 * 10,
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token || Cookies.get('admin_token')}`,
      },
      error: {
          code: 'INTERNAL ERROR',
          message: 'Something went wrong.',
          status: 503,
          data: {},
      }
  })


    const logout = () => {
      localStorage.clear()
      Cookies.remove('profile')
      Cookies.remove('admin_token')
      Cookies.remove('token')
      setAuthentication(false)
      history.push('/')
    }

    const uploadAPI = (boundary) => axios.create({
      baseURL: API_URL,
      timeout: 1000 * 10, 
      headers: {
         'Content-Type': 'multipart/form-data; boundary=' + boundary,
          Authorization: `Bearer ${Cookies.get("token") || localStorage.getItem("token")}`,
      },
      error: {
          code: 'INTERNAL ERROR',
          message: 'Something went wrong.',
          status: 503,
          data: {},
      }
    })

    const saveProfile = profile => {

      if (typeof Cookies.get("profile") === 'undefined') {
        Cookies.set("profile", JSON.stringify(profile))

        setCustomer({
          ...customer,
          profile})
      } 
    }

    const getProfile = () => {
      if (typeof Cookies.get("profile") === 'undefined') return
      return JSON.parse(Cookies.get("profile"))
    }

    const sessionIsAvailable = () => {

      if (debug ) {
        setAuthentication(true)
        return true
      }
      
      if (typeof Cookies.get("profile") === 'undefined' || typeof Cookies.get("token") === 'undefined') {
        localStorage.clear()
        Cookies.remove('profile')
        setAuthentication(false)
        return false
      }

      setAuthentication(true)

      return true
    }

   const get = async (url, onSuccess, onError, cancelToken) => {

      const token = Cookies.get('token')

      try {
        const response = await api(token).get(url, {cancelToken: cancelToken})
        if (typeof onSuccess === 'function') {
          onSuccess(response.data) 

        }
      } catch (error) {
        if (error && error.response && error.response.status && error.response.status === 401) {
          setModal({type: ModalType.ERROR , message: "Your session has expired."})
          logout()
          return
        }
        if (typeof onError === 'function' && error) {
          const {response} = error
          if (response && response.data) {
            onError((response.data.messages && response.data.messages.toString()) || response.data.title)
          }
        }
      }
   }

  const getCustomer = async (onSuccess, onError) => {

    if (debug) {
      onSuccess()
      return
    }

    // if (localStorage.getItem("onboarding")) {
    //   return
    // }

    console.log("getcustomer")
  if (sessionIsAvailable()) {
    const profile = getProfile()
    setCustomer({
      ...customer,
      profile})
      setAuthentication(true)
      if (typeof onSuccess === 'function') {
        onSuccess() 
      }
    return
  }

  if (typeof Cookies.get("token") === 'undefined') return

  const token = Cookies.get('token')
  const jwt = parseJwt(token)
  const email = jwt["email"] || ""

  try {
      const response = await api(token).get("/customer/profile")
      const {customerId, fullName, shortName, uid, contactName, dba, title, officeNumber, mobileNumber} = response.data.data
      const profile = {
        uid,
        email,
        customerId,
        fullName,
        shortName,
        dba,
        title,
        mobileNumber,
        officeNumber,
        contactName
      }

      if (!localStorage.getItem("customerId")) {
        localStorage.setItem("customerId", customerId)         
     }
      saveProfile(profile)
      setAuthentication(true)

      if (typeof onSuccess === 'function') {
        onSuccess() 
      }
  } catch (error) {
    setAuthentication(false)

    if (error && error.response && error.response.status && error.response.status === 401) {
      setModal({type: ModalType.ERROR , message: "Your session has expired."})
      logout()
      return
    }


    if (typeof onError === 'function' && error && error.response && error.response.data) {
      const errorMessage = (error.response.data.messages && error.response.data.messages.toString()) || "Failed to get profile"
      onError(errorMessage)
    }
  }
  }


  const loadCart = async (onSuccess, onError) => {
    try {
      const cart = await api().get("/cart")
      if (typeof onSuccess === 'function') {
        onSuccess(cart.data) 
      }
    } catch (error) {

      if (error && error.response && error.response.status && error.response.status === 401) {
        setModal({type: ModalType.ERROR , message: "Your session has expired."})
        logout()
        return
      }
      
      if (typeof onError === 'function' && error && error.response && error.response.data) {
        const errorMessage = (error.response.data.messages && error.response.data.messages.toString()) || "Failed to get profile"
        onError(errorMessage)    
      }
    }
 }

  return {
    api,
    adminAPI,
    getCustomer,
    loadCart,
    get,
    uploadAPI,
    logout,
    sessionIsAvailable,
  }
}