import styled from 'styled-components'
import {color, font} from 'shared/theme'
import {device} from 'shared/layout'

const RxPlBase = styled.div`
   position: relative;
   padding: 48px 32px;
   background: ${color.white};
   border-radius: 16px;
`
export const RxPlSignin = styled(RxPlBase)`
   width: 100%;
   height: 100%;
   border-radius: 0;
  > p{
      width: 100%;
      text-align: center;
   }

   button {
      &.resend-btn {
         font: ${font.bold};
         color: ${color.sky};
      }

      &.sky-text {
         color: ${color.sky};
      }
   }

   .error-message {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background: ${color.error} !important;
      padding: 8px 32px;
      color: ${color.white};

      p {
         margin-bottom: 0;
         text-align: center;
      }

      button {
         color: ${color.white};
         margin: 0;
         padding: 0;
         text-decoration: underline;
      }
   }

   .success-message {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background: ${color.success} !important;
      padding: 8px 32px;
      color: ${color.white};

      p {
         margin-bottom: 0;
         text-align: center;
      }
   }

   .spinner {
      position: absolute; 
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
   }

   &.forgot-password {
      input {
         margin-bottom: 16px;
      }
   }
   @media only screen and ${device.sm} {
      width: 438px;
      height: auto;
      border-radius: 16px;
      height: auto;

   }
   
`;

RxPlSignin.FormGroup = styled.div`
   margin-bottom: 16px;
   height: 100%;
   input {
      width: 100%;
      margin: 0;
   }

   @media only screen and ${device.sm} {
      height: auto;
   }
`

RxPlSignin.ButtonGroup = styled.div`
   margin: 24px 40px;

   button {
      margin: 0;
      width: 100%;
      display: block;
      margin-bottom: 24px;
   }
`


export const RxPlProductDetail = styled(RxPlBase)`
   width: 962px;
`
RxPlProductDetail.Wrapper = styled.div`
   display: flex;

   button {
      margin:0;

      > * {
         flex-basis: 0;
         flex-grow: 1;
      }
   }
`

RxPlProductDetail.ControlsWrapper = styled.div`
   display: flex;
   margin: 0 -16px;
   button {
      margin:0;


   }

   select,
   button {
      flex-basis: 0;
         flex-grow: 1;
         margin: 0 16px;
   }


`
RxPlProductDetail.Info = styled.div`
   flex-grow: 2;
   padding-right: 32px;
`

RxPlProductDetail.Image = styled.div`
`


RxPlProductDetail.Header = styled.div`
   display: flex;
   justify-content: space-between;
`

export const RxPlUserAgreement = styled(RxPlBase)`
   width: 67%;
   max-width: 980px;
   padding: 60px;
   text-align: center;
   max-height: 60%;

   h6 {
      font-size: 18px;
   }

   .outer-wrapper {
      overflow: auto;
      height: 100%;
   }
   .checkbox {
      width: 24px;
      height: 24px;


      .control {
         width: 24px;
         display: none !important;
      }
      .checkmark {
         min-width: 24px;
      }
   }
   p {
      text-align: left;
      margin-left: 32px;
   }

   .flex {
      display: flex;
   }


   .user-agreement-popup-btn {
      color:  ${color.sky};
      cursor: pointer;
      text-decoration: underline;
   }

   &.popup {
      padding: 32px;
      height: 600px;

      ol,
      ul {
         li {text-align: left;}
      }
      h4 {
         margin-bottom: 30px;
         margin-top: 40px;
         text-align: left;
         span {
            font-size: 24px;
            line-height: 24px;
            ${font.regular};
         }
      }

      p {
         margin-left: 0;
      }
   }
`

export const RxPlError = styled(RxPlBase)`
   width: 438px;
   background: ${color.error};
  > p{
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      color: white;
   }
`;

export const RxPlUtility = styled(RxPlBase)`
   width: 100%;
   height: 100%;
   border-radius: 0;

   a {
      text-decoration: underline;
   }
   .wrapper {
      height: 100%;
      max-height: unset;
      overflow-y: auto;

      ul {
         list-style: disc;
         margin-left: 1.2em;
      }
   }

   @media only screen and ${device.sm} {
      width: 600px;
      max-width: 962px;
      height: auto;
      border-radius: 16px;

      .wrapper {
         max-height: 620px;
      }

   }
`

export const RxPlSurvey = styled(RxPlBase)`
   max-width: 450px;
   text-align: center;
`


export const RxPlRequestAccess = styled(RxPlBase)`
   width: 100%;
   max-width: 1320px;
   border-radius: unset;
   display: block;
   max-height: 660px;
   padding: 0;
   position: relative;


   
   div {
      flex: 1;

      img {
         width: 100%;
         min-height: 100%;
         object-fit: cover;
      }
   }

   .image {
      display: none;
   }
   
   .close-btn {
      top: 0.5rem;
      right: 0.5rem;
      z-index: 1;
   }

   h2 {
      ${font.regular}
      font-size: 2.375rem;
      line-height: 2.875rem;
   }

   form {
      margin-top: 32px;
      input[type="email"] {
         background: none;
         border: none;
         padding-left: 0;
         border-radius: 0;
         width: 100%;
         max-width: 530px;
         margin-bottom: 16px;
         border-bottom: 1px solid ${color.grayPrimary};
      }

      input[type='submit'] {
         min-width: 160px;
         color: ${color.white};
         background: ${color.sky};
         border: 0;
      }

      input {
         margin: 0;
      }

      > div {
         margin-bottom: 0;
      }

      .button-group {
         display: block;
         button {

            font-size: 1.25rem;
            margin-top: 1.5rem;
         }
      }
   }
   
   [type="signup"] {
      border-radius: unset;
      min-height: 420px;
      background: ${color.sky} !important;
      padding: 3rem !important;
      input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
         color: white !important;
         opacity: 1; /* Firefox */
       }

       input:-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: white !important;
      }

      input::-ms-input-placeholder { /* Microsoft Edge */
         color: white !important;
      }

      &.vertical-center {
         display: flex;
         align-items:center;
      }
      input {
         border: 0;
         appearance: none;
         border-radius: 0;
         background: none;
         border-bottom: 1px solid white;
         color: white;
         padding-left: 0;
         padding-right: 0;

         &:focus,
         &:focus-visible {
            appearance: none;
            outline: none;
            border-bottom: 1px solid white;

         }
      }

      button {
         width: 100%;
         font-size: 28px;
         ${font.bold}
      }


      h3 {
         font-size: 2.375rem;
         ${font.regular}
      }

      .grecaptcha-badge {
         position: relative !important;
         bottom: auto !important;
         right: auto !important;
         transition: none !important;
         box-shadow: unset !important;
         border-radius: unset !important;
         iframe {
            width: 100% !important;
         }
      }

      @media only screen and ${device.sm} {
         max-width: 67%;
      }
   }

   .button-group {
      margin-top: 3rem;
   }

   @media only screen and ${device.sm} {
      display: flex;

      .image {
         display: block;
      }

      form {
         > div {
            margin-bottom: 3rem;
         }

         .button-group {
            display: flex;
            margin-top: 0;
            button {
               margin-top: 0;
            }
         }
      }
   }
   
`;